import { useCallback, useEffect, useState } from "react";
import ReactFlow, {
  useNodesState,
  useEdgesState,
  addEdge,
  Node,
  Position,
  applyNodeChanges,
  applyEdgeChanges,
  NodeChange,
  EdgeChange,
} from "reactflow";

// 👇 you need to import the reactflow styles
import "reactflow/dist/style.css";

import { colors } from "../../utilities/style";

const defaultNodeConfiguration = {
  sourcePosition: Position.Right,
  targetPosition: Position.Left,
  draggable: false,
  connectable: false,
  style: {
    width: "auto",
    borderRadius: "0",
    backgroundColor: colors.white,
  },
};

const highImpactNodeStyle = {
  borderColor: colors.primary,
  ...defaultNodeConfiguration.style,
};

const mediumImpactNodeStyle = {
  borderColor: colors.tertiary,
  ...defaultNodeConfiguration.style,
};

const correctedNodeStyle = {
  borderColor: colors.secondary,
  ...defaultNodeConfiguration.style,
};

let initialNodes: Node[] = [
  {
    id: "1",
    type: "input",
    position: { x: 0, y: 0 },
    data: { label: "source.stripe.customers" },
  },
  {
    id: "6",
    type: "input",
    position: { x: 0, y: 100 },
    data: { label: "source.stripe.subscriptions" },
  },
  {
    id: "2",
    position: {
      x: 300,
      y: 0,
    },
    data: { label: "stg_stripe__customers" },
  },
  {
    id: "3",
    position: {
      x: 550,
      y: 100,
    },
    data: { label: "user_subscriptions" },
    style: { ...mediumImpactNodeStyle },
  },
  {
    id: "4",
    type: "output",
    position: { x: 550, y: 0 },
    data: { label: "customers" },
  },
  {
    id: "7",
    type: "output",
    position: { x: 800, y: 150 },
    data: { label: "revenue" },
    style: { ...highImpactNodeStyle },
  },
  {
    id: "8",
    type: "output",
    position: { x: 800, y: 50 },
    data: { label: "churn_rate" },
    style: { ...highImpactNodeStyle },
  },
  {
    id: "5",
    type: "input",
    position: { x: 0, y: 200 },
    data: { label: "source.platform.users" },
  },
];

const defaultEdgeConfiguration = {
  labelBgStyle: { fill: colors.white },
};

const highImpactEdgeStyle = {
  stroke: colors.primary,
};

const mediumImpactEdgeStyle = {
  stroke: colors.tertiary,
};

const correctedEdgeStyle = {
  stroke: colors.secondary,
};

let initialEdges = [
  { id: "e1-2", source: "1", target: "2" },
  {
    id: "e5-3",
    source: "5",
    target: "3",
    style: mediumImpactEdgeStyle,
    label: "Missing staging model",
  },
  {
    id: "e1-3",
    source: "1",
    target: "3",
    style: mediumImpactEdgeStyle,
    label: "Missing staging model",
  },
  { id: "e2-4", source: "2", target: "4" },
  { id: "e3-7", source: "3", target: "7" },
  {
    id: "e6-3",
    source: "6",
    target: "3",
    style: mediumImpactEdgeStyle,
    label: "Missing staging model",
  },
  {
    id: "e6-7",
    source: "6",
    target: "7",
    style: highImpactEdgeStyle,
    label: "Direct source reference",
  },
  {
    id: "e6-8",
    source: "6",
    target: "8",
    style: highImpactEdgeStyle,
    label: "Direct source reference",
  },
  {
    id: "e2-8",
    source: "2",
    target: "8",
    style: mediumImpactEdgeStyle,
    label: "Referencing staging model and not entity.",
  },
];

initialNodes = initialNodes.map((node) => {
  return { ...defaultNodeConfiguration, ...node };
});

initialEdges = initialEdges.map((edge) => {
  return { ...defaultEdgeConfiguration, ...edge };
});

// enum Event {
//   Add,
//   Remove,
//   Update,
// }

// enum EntityTypes {
//   Node,
//   Edge,
// }

// interface Step {
//   event: Event;
//   entityId: string;
//   entityType: EntityTypes;
//   data: Node | Edge;
// }

interface StepGroup {
  nodes: NodeChange[];
  edges: EdgeChange[];
}

const steps: StepGroup[] = [
  {
    nodes: [
      {
        type: "add",
        item: {
          id: "9",
          position: { x: 300, y: 100 },
          data: { label: "stg_stripe__subscriptions" },
          ...defaultNodeConfiguration,
          style: { ...correctedNodeStyle },
        },
      },
    ],
    edges: [
      {
        type: "remove",
        id: "e6-3",
      },
      {
        type: "add",
        item: {
          id: "e6-9",
          source: "6",
          target: "9",
          style: correctedEdgeStyle,
        },
      },

      {
        type: "add",
        item: {
          id: "e9-3",
          source: "9",
          target: "3",
          style: correctedEdgeStyle,
        },
      },
    ],
  },
  {
    nodes: [
      {
        type: "add",
        item: {
          id: "10",
          position: { x: 300, y: 200 },
          data: { label: "stg_platform__users" },
          ...defaultNodeConfiguration,
          style: { ...correctedNodeStyle },
        },
      },
    ],
    edges: [
      {
        type: "add",
        item: {
          id: "e5-10",
          source: "5",
          target: "10",
          style: correctedEdgeStyle,
        },
      },
      {
        type: "add",
        item: {
          id: "e10-3",
          source: "10",
          target: "3",
          style: correctedEdgeStyle,
        },
      },
      {
        type: "remove",
        id: "e5-3",
      },
    ],
  },
  {
    nodes: [
      {
        type: "remove",
        id: "3",
      },
      {
        type: "add",
        item: {
          id: "3",
          position: {
            x: 550,
            y: 100,
          },
          data: { label: "user_subscriptions" },
          ...defaultNodeConfiguration,
          style: { ...correctedNodeStyle },
        },
      },
    ],
    edges: [
      {
        type: "remove",
        id: "e1-3",
      },
      {
        type: "remove",
        id: "e3-4",
      },
      {
        type: "add",
        item: {
          id: "e2-3",
          source: "2",
          target: "3",
          style: correctedEdgeStyle,
        },
      },
    ],
  },
  {
    nodes: [
      {
        type: "remove",
        id: "7",
      },
      {
        type: "add",
        item: {
          id: "7",
          type: "output",
          position: { x: 800, y: 150 },
          data: { label: "revenue" },
          ...defaultNodeConfiguration,
          style: { ...correctedNodeStyle },
        },
      },
    ],
    edges: [
      {
        type: "remove",
        id: "e6-7",
      },
      {
        type: "remove",
        id: "e6-8",
      },
      {
        type: "add",
        item: {
          id: "e3-7",
          source: "3",
          target: "7",
          style: correctedEdgeStyle,
        },
      },
      {
        type: "add",
        item: {
          id: "e3-8",
          source: "3",
          target: "8",
          style: correctedEdgeStyle,
        },
      },
    ],
  },
  {
    nodes: [
      {
        type: "remove",
        id: "8",
      },
      {
        type: "add",
        item: {
          id: "8",
          type: "output",
          position: { x: 800, y: 50 },
          data: { label: "churn_rate" },
          ...defaultNodeConfiguration,
          style: { ...correctedNodeStyle },
        },
      },
    ],
    edges: [
      {
        type: "remove",
        id: "e2-8",
      },

      {
        type: "add",
        item: {
          id: "e4-8",
          source: "4",
          target: "8",
          style: correctedEdgeStyle,
        },
      },
    ],
  },
];

export const MarketingGraph: React.FC = () => {
  const [nodes, setNodes] = useNodesState(initialNodes);
  const [edges, setEdges] = useEdgesState(initialEdges);
  const [step, setStep] = useState(0);

  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    [setEdges]
  );

  const onNodesChange = useCallback(
    (changes: NodeChange[]) =>
      setNodes((nds) => applyNodeChanges(changes, nds)),
    [setNodes]
  );

  const onEdgesChange = useCallback(
    (changes: EdgeChange[]) =>
      setEdges((eds) => applyEdgeChanges(changes, eds)),
    [setEdges]
  );

  const handleStep = useCallback(() => {
    if (step >= steps.length) {
      setNodes(initialNodes);
      setEdges(initialEdges);
      setStep(0);
    }

    let stepGroup: StepGroup = steps[step];

    if (!stepGroup) {
      setStep(0);
      return;
    }

    onNodesChange(stepGroup.nodes);
    onEdgesChange(stepGroup.edges);

    setStep(step + 1);
  }, [setStep, setNodes, setEdges, step, onEdgesChange, onNodesChange]);

  useEffect(() => {
    setTimeout(handleStep, 2000);
  }, [step, handleStep]);

  return (
    <div style={{ height: "400px" }}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        zoomOnScroll={false}
        zoomOnPinch={false}
        panOnDrag={false}
        preventScrolling={false}
        proOptions={{ hideAttribution: true }}
        fitView
      ></ReactFlow>
    </div>
  );
};
