import styled from "styled-components";
import { Run, RunStatisticDict } from "../../utilities/api/runs";
import { colors } from "../../utilities/style";
import { RunDetails } from "../results/runDetails";
import RunMetadataCard from "../results/runMetadata";

const RunResultsCardWrapper = styled.div`
  margin: 0 auto;
  padding: 1em;
  //   border: 2px dashed ${colors.primary};
`;

interface RunResultsCardProps {
  metrics: RunStatisticDict;
}

export const RunResultsCard: React.FC<RunResultsCardProps> = ({ metrics }) => {
  return (
    <RunResultsCardWrapper>
      <b>Summary</b>
      <RunDetails metrics={metrics} />
    </RunResultsCardWrapper>
  );
};

interface ProjectSummaryProps {
  run: Run;
  retryHandler?: () => void;
}

const ReportHeader = styled.div`
  display: flex;
  align-items: baseline;
`;

export const RunSummary: React.FC<ProjectSummaryProps> = ({
  run,
  retryHandler,
}) => {
  return (
    <div style={{ width: "100%" }}>
      <ReportHeader>
        <RunMetadataCard run={run} retryHandler={retryHandler} />
        <RunResultsCard metrics={run.statistics || {}} />
      </ReportHeader>
    </div>
  );
};
