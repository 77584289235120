import styled, { css } from "styled-components";
import { colors } from "../../utilities/style";

interface LoadingIndicatorProps {
  status: "loading" | "error" | "success";
  messages: {
    loading: string;
    error: string;
    success: string;
  };
}

interface IndicatorDivProps {
  color: string;
}

const IndicatorWrapper = styled.div<IndicatorDivProps>`
  margin: 1em auto;
  padding: 0.5em;

  ${(props) =>
    props.color &&
    css`
      border: 1px solid ${props.color};
      color: ${props.color};
      background-color: ${props.color}05;
    `}

  p {
    filter: brightness(60%);
  }
`;

const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({
  status,
  messages,
}) => {
  const colorMap = {
    loading: colors.gray,
    error: colors.primary,
    success: colors.secondary,
  };

  return (
    <IndicatorWrapper color={colorMap[status]}>
      <span>{messages[status]}</span>
    </IndicatorWrapper>
  );
};

export default LoadingIndicator;
