import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import styled from "styled-components";

import { Result, Severity } from "../../utilities/api/results";
import { colors } from "../../utilities/style";

interface CardProps {
  result: Result;
  isFirst: boolean;
}

interface CardDivProps {
  isFirst: boolean;
  severity: Severity;
}

const CardDiv = styled.div<CardDivProps>`
  width: 100%;
  margin: 0;

  box-sizing: border-box;

  border-top: ${(props) => (props.isFirst ? "1px solid #bbbbbb" : "0px")};
  border-left: 1px solid #bbbbbb;
  border-right: 1px solid #bbbbbb;
  border-bottom: 0.5px solid #bbbbbb;
`;

interface CardHeaderProps {
  severity: Severity;
  collapsed: boolean;
}

interface SeverityProps {
  severity: Severity;
}

const CardHeader = styled.div<CardHeaderProps>`
  // font-size: medium;
  // font-weight: bold;
  // margin: 0em 0em;

  width: 100%;
  cursor: pointer;

  padding: 0.5em;
  margin: 0;

  display: flex;
  flex-wrap: nowrap;
  column-gap: 1em;
  align-items: center;

  box-sizing: border-box;

  border-bottom: ${(props) => (props.collapsed ? "0px" : "1px solid #bbbbbb")};

  border-left-width: 0.5em;
  border-left-style: solid;
  border-left-color: ${(props) => severityColorMap[props.severity]};

  background-color: ${(props) =>
    props.collapsed ? colors.white : `${colors.primary + "10"}`};
  &:hover {
    background-color: ${colors.primary + "10"};
  }

  &::after {
    content: ">";
    display: inline-block;
    margin-left: auto;
    margin-right: 1em;
    transform: rotate(${(props) => (props.collapsed ? "0" : "90deg")});
  }

  @media only screen and (max-width: 600px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    column-gap: 0em;

    span {
      font-size: 0.75em;
      padding: 0 1em 0 0;
      border-left: 0;
      border-right: 0;
    }

    h4 {
      flex-basis: 90%;
      font-size: 1em;
    }
  }
`;

interface ICardDescription {
  collapsed: boolean;
}

const CardDescription = styled.div<ICardDescription>`
  font-weight: thin;
  font-size: small;
  margin: 0.75em 0 0.75em 0;
  padding: 0 1em;
  display: ${(props) => (props.collapsed ? "none" : "block")};

  @media only screen and (max-width: 600px) {
    overflow-wrap: break-word;
  }
`;

const ResultType = styled.span`
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: #dddddd;

  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: #dddddd;
  padding: 0 1em;

  font-weight: thin;
  font-size: small;

  min-width: 10em;
`;

const severityColorMap = {
  low: colors.white,
  medium: colors.tertiary,
  high: colors.primary,
};

const ResultSeverity = styled.span<SeverityProps>`
  padding: 0 1em;

  min-width: 3.75em;
  text-transform: capitalize;
  font-size: small;
  font-weight: 600;

  text-align: left;
  text-decoration: bold;

  color: ${(props) =>
    severityColorMap[props.severity] === colors.white
      ? colors.black
      : severityColorMap[props.severity]};
`;

const ResultTitle = styled.h4`
  margin: 0em;
  display: inline;

  font-weight: thin;
  font-size: small;
`;

const Card: React.FC<CardProps> = ({ result, isFirst, children }) => {
  const [collapsed, setCollapsed] = useState(true);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  return (
    <CardDiv isFirst={isFirst} severity={result.severity}>
      <div>
        <CardHeader
          severity={result.severity}
          onClick={toggleCollapse}
          collapsed={collapsed}
        >
          <ResultSeverity severity={result.severity}>
            {result.severity}
          </ResultSeverity>
          <ResultType>{result.rule_set}</ResultType>
          <ResultTitle>{result.content.name}</ResultTitle>
        </CardHeader>
        <CardDescription collapsed={collapsed}>
          <ReactMarkdown>{result.summary}</ReactMarkdown>
        </CardDescription>
      </div>
    </CardDiv>
  );
};

export default Card;
