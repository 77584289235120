import React, { useEffect, useContext } from "react";

import Graph from "graphology";
// import circular from 'graphology-layout/circular';
// import forceAtlas2 from 'graphology-layout-forceatlas2';
import {
  SigmaContainer,
  useLoadGraph,
  useRegisterEvents,
} from "@react-sigma/core";
import "@react-sigma/core/lib/react-sigma.min.css";
import { colors } from "../../utilities/style";
import { GraphCacheContext } from "../../utilities/graphCache";
import { TouchCoords } from "sigma/types";

interface DisplayGraphProps {
  id: string;
  graph: Graph;
  layout: boolean;
  iterations?: number;
  gravity?: number;
  afterRenderCallback: () => void;
}

export const LoadGraph: React.FC<DisplayGraphProps> = ({
  id,
  graph,
  layout,
  afterRenderCallback,
  // iterations,
  // gravity,
}) => {
  const { addGraph } = useContext(GraphCacheContext);
  // const [isComplete, setIsComplete] = useState(false);
  const loadGraph = useLoadGraph();

  useEffect(
    () => {
      // if (isComplete) return;

      if (layout) {
        graph.updateEachNodeAttributes((node, attr) => {
          if (node.startsWith("source")) {
            return {
              ...attr,
              color: colors.secondary,
            };
          } else if (node.startsWith("exposure")) {
            return {
              ...attr,
              color: colors.black,
            };
          }
          return attr;
        });

        addGraph(id, graph, true);
      }

      try {
        loadGraph(graph);
      } catch {
        console.error("Whoopsie!");
      }

      // setIsComplete(true);
      afterRenderCallback();
    },
    [graph] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return null;
};

export const GraphEvents: React.FC = () => {
  const registerEvents = useRegisterEvents();

  useEffect(() => {
    // Register the events
    registerEvents({
      // node events

      wheelNode: (event) => event.preventSigmaDefault,

      // edge events

      wheelEdge: (event) => event.preventSigmaDefault,

      enterEdge: (event) => {},
      leaveEdge: (event) => {},
      // stage events

      wheelStage: (event) => event.preventSigmaDefault,

      // default mouse events

      doubleClickStage: (event) => {
        event.preventSigmaDefault();
        // event.original.preventDefault();
        // event.original.stopPropagation();
      },

      doubleClickEdge: (event) => {
        event.preventSigmaDefault();
        // event.original.preventDefault();
        // event.original.stopPropagation();
      },

      doubleClickNode: (event) => {
        event.preventSigmaDefault();
        // event.original.preventDefault();
        // event.original.stopPropagation();
      },

      wheel: (event) => {
        event.preventSigmaDefault();
        // event.original.preventDefault();
        // event.original.stopPropagation();
      },

      mousedown: (event) => {
        event.preventSigmaDefault();
        event.original.preventDefault();
        event.original.stopPropagation();
      },
      mousemove: (event) => {
        event.preventSigmaDefault();
        event.original.preventDefault();
        event.original.stopPropagation();
      },
      click: (event) => {
        event.preventSigmaDefault();
        event.original.preventDefault();
        event.original.stopPropagation();
      },

      // default touch events
      touchup: (event) => {},
      touchdown: (event) => {
        event.original.preventDefault();
        event.original.stopPropagation();
      },
      touchmove: (event: TouchCoords) => {
        // event.preventDefault();
        event.original.preventDefault();
        event.original.stopPropagation();
      },

      clickStage: (event) => {
        event.preventSigmaDefault();
      },

      // sigma camera update
    });
  }, [registerEvents]);

  return null;
};

export const DisplayGraph: React.FC<DisplayGraphProps> = ({
  id,
  graph,
  layout,

  afterRenderCallback,
}) => {
  return (
    <div>
      <div
        style={{
          position: "absolute",
          width: "700px",
          height: "350px",
          zIndex: 1,
        }}
      ></div>
      <SigmaContainer
        style={{
          height: "350px",
          width: "700px",
          backgroundColor: colors.white,
        }}
      >
        <GraphEvents />
        <LoadGraph
          layout={layout}
          id={id}
          graph={graph}
          afterRenderCallback={afterRenderCallback}
        />
      </SigmaContainer>
    </div>
  );
};
