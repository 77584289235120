import { ChangeEvent, useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";

import Alert from "../../components/alert/alert";
import { Button } from "../../components/button/Button";
import { Input } from "../../components/forms/forms";
import { Breadcrumbs } from "../../components/header/breadcrumbs";
import { DeleteDialog } from "../../components/project/deleteDialog";
import { APIResponse } from "../../utilities/api/index";
import {
  Account,
  fetchAccountbyId,
  fetchCurrentUser,
  User,
} from "../../utilities/api";
import {
  fetchProjectForAccount,
  Project,
  updateProject,
} from "../../utilities/api/projects";
import {
  createWebhook,
  fetchWebhooksForProject,
  getWebhook,
  updateWebhook,
  Webhook,
} from "../../utilities/api/webhooks";
import { colors } from "../../utilities/style";
import LoadingIndicator from "../../components/forms/indicator";

const DashboardPageContainer = styled.div`
  max-width: 70em;
`;

const StyledHR = styled.hr`
  color: ${colors.lightgray};
`;

const Section = styled.section`
  margin: 5em 0;
`;

const EditProjectPage: React.FC = () => {
  const navigate = useNavigate();
  const { accountId, projectId } = useParams();

  const [deleteDialogOpen, setDeleteDialog] = useState(false);

  const [projectName, setProjectName] = useState<string | undefined>();
  const [projectMutationStatus, setProjectMutationStatus] =
    useState<"loading" | "success" | "error" | undefined>();

  const [webhookId, setWebhookId] = useState<string>(uuidv4());

  const [webhookAuthorization, setWebhookAuthorization] =
    useState<string | undefined>();
  const [webhookServiceToken, setWebhookServiceToken] =
    useState<string | undefined>();
  const [webhookMutationStatus, setWebhookMutationStatus] =
    useState<"loading" | "success" | "error" | undefined>();

  const { data: user } = useQuery<User, Error>("user", fetchCurrentUser);

  if (!accountId && user) {
    navigate(`/accounts/${user?.accounts[0].id}`);
  }

  const { isSuccess: isAccountSuccess, data: account } = useQuery<
    Account,
    Error
  >(
    ["accounts", { accountId }],
    ({ queryKey }: any) =>
      fetchAccountbyId({ accountId: queryKey[1].accountId }),
    {
      enabled: !!accountId,
    }
  );

  const { isSuccess: isProjectSuccess, data: project } = useQuery<
    Project,
    Error
  >(
    ["projects", { accountId, projectId }],
    ({ queryKey }: any) =>
      fetchProjectForAccount({
        accountId: queryKey[1].accountId,
        projectId: queryKey[1].projectId,
      }),
    {
      // The query will not execute until the userId exists

      enabled: !!accountId && !!projectId,
    }
  );

  const { isSuccess: isWebhookSuccess, data: webhooks } = useQuery<
    APIResponse<Webhook[]>,
    Error
  >(
    ["webhooks", { accountId, projectId }],
    ({ queryKey }: any) =>
      fetchWebhooksForProject({
        accountId: queryKey[1].accountId,
        projectId: queryKey[1].projectId,
      }),
    {
      // The query will not execute until the userId exists

      enabled: !!accountId && !!projectId,
    }
  );

  const { data: currentWebhook } = useQuery<APIResponse<Webhook>, Error>(
    [
      "webhooks",
      {
        accountId,
        projectId,
        webhookId: webhooks
          ? webhooks.data.length > 0
            ? webhooks.data[0].id
            : ""
          : "",
      },
    ],
    ({ queryKey }: any) =>
      getWebhook({
        accountId: queryKey[1].accountId,
        projectId: queryKey[1].projectId,
        webhookId: queryKey[1].webhookId,
      }),
    {
      // The query will not execute until the userId exists

      enabled:
        !!accountId &&
        !!projectId &&
        isWebhookSuccess &&
        !!webhooks &&
        webhooks.data.length > 0,
    }
  );

  useEffect(() => {
    if (!!currentWebhook) {
      setWebhookId(currentWebhook.data.id);
      setWebhookAuthorization(currentWebhook.data.authorization);
      setWebhookServiceToken(currentWebhook.data.service_token);
    }
  }, [currentWebhook]);

  const queryClient = useQueryClient();

  const mutation = useMutation(updateProject, {
    onMutate: () => {
      setProjectMutationStatus("loading");
    },
    onError: () => {
      setProjectMutationStatus("error");
    },
    onSuccess: (data) => {
      setProjectMutationStatus("success");
      // Invalidate and refetch
      queryClient.setQueryData(
        [
          "projects",
          {
            accountId: !!project ? project.account_id.toString() : "",
            projectId: !!project ? project.id.toString() : "",
          },
        ],
        data
      );
    },
  });

  const createWebhookMutation = useMutation(createWebhook, {
    onMutate: () => {
      setWebhookMutationStatus("loading");
    },
    onError: () => {
      setWebhookMutationStatus("error");
    },
    onSuccess: (data) => {
      setWebhookMutationStatus("success");
      // Invalidate and refetch
      queryClient.setQueryData(
        [
          "webhooks",
          {
            accountId: data.data.account_id,
            projectId: data.data.project_id,
            webhookId: data.data.id,
          },
        ],
        data
      );
    },
  });

  const updateWebhookMutation = useMutation(updateWebhook, {
    onMutate: () => {
      setWebhookMutationStatus("loading");
    },
    onError: () => {
      setWebhookMutationStatus("error");
    },
    onSuccess: (data) => {
      setWebhookMutationStatus("success");
      // Invalidate and refetch
      queryClient.setQueryData(
        [
          "webhooks",
          {
            accountId: data.data.account_id,
            projectId: data.data.project_id,
            webhookId: data.data.id,
          },
        ],
        data
      );
    },
  });

  const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!projectName) {
      console.warn("Attempted to save a project with an undefined name.");
      return;
    }

    mutation.mutate({
      accountId: !!project ? project.account_id : 0,
      projectId: !!project ? project.id : 0,
      projectUpdate: {
        name: projectName as string,
        status: "active",
      },
    });
  };

  const handleWebhookSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!webhookAuthorization || !webhookServiceToken) {
      console.warn("Attempted to save a webhook with missing data.");
      return;
    }

    if (!!currentWebhook) {
      // Update the webhook
      updateWebhookMutation.mutate({
        accountId: currentWebhook.data.account_id,
        projectId: currentWebhook.data.project_id,
        webhookId: currentWebhook.data.id,
        webhookUpdate: {
          service_token: webhookServiceToken,
          authorization: webhookAuthorization,
        },
      });
    } else {
      // Create a new webhook
      createWebhookMutation.mutate({
        accountId: !!project ? project.account_id : 0,
        projectId: !!project ? project.id : 0,
        webhook: {
          id: webhookId,
          system: "dbt_cloud",
          authorization: webhookAuthorization,
          service_token: webhookServiceToken,
        },
      });
    }
  };

  return (
    <DashboardPageContainer>
      {account && isAccountSuccess && isProjectSuccess && project ? (
        <Breadcrumbs
          links={[
            { name: `${account.name}`, url: `/accounts/${accountId}` },

            {
              name: `${project.name}`,
              url: `/accounts/${accountId}/projects/${project.id}`,
            },
          ]}
        />
      ) : (
        <Breadcrumbs links={[{ name: " ", url: `/accounts/${accountId}` }]} />
      )}
      {isProjectSuccess && project && account ? (
        <div>
          <h1 style={{ margin: "0.5em 0 0 0" }}>Edit: {project.name}</h1>

          <Section>
            <h2>Project Configuration</h2>
            <p>
              Basic settings for the <code>{project.name}</code> project.
            </p>
            <form onSubmit={handleSubmit}>
              <fieldset
                style={{
                  maxWidth: "35em",
                  margin: "2em auto",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Input
                  type="text"
                  id="webhookSecretToken"
                  name="projectName"
                  label="Name:"
                  value={projectName || project.name}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setProjectName(event.target.value);
                  }}
                />

                <Button
                  type="submit"
                  size="tiny"
                  label={"Save"}
                  primary
                  style={{ maxWidth: "5em", margin: "0 auto" }}
                />
                {!!projectMutationStatus ? (
                  <LoadingIndicator
                    status={projectMutationStatus}
                    messages={{
                      success: "Project Saved",
                      loading: "Saving Project",
                      error: "Error!",
                    }}
                  />
                ) : undefined}
              </fieldset>
            </form>
          </Section>
          <StyledHR />
          <Section>
            <h2>Integrations</h2>
            <p>
              Integrate the <code>{project.name}</code> project with other tools
              to enable automated project analysis.
            </p>
            <h3>dbt Cloud</h3>
            <Alert type="warning">
              Our dbt Cloud integration is <i>very</i> new and we're still in
              the process of working out all of the final touches. Please give
              it a try and let us know where is shines and where it catches on
              fire.
            </Alert>
            <p>
              Integrating Whetstone with dbt Cloud allows your Whetstone project
              to automatically run a new analysis when your dbt Cloud jobs
              complete. To setup a new dbt Cloud webhook, please follow dbt
              Labs'{" "}
              <a href="https://docs.getdbt.com/docs/deploy/webhooks#create-a-webhook-subscription">
                instructions for creating a webhook subscription
              </a>{" "}
              using the following URL as your endpoint.
            </p>
            <pre
              style={{
                margin: "3em auto",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              {process.env.REACT_APP_API_URL}/webhooks/{webhookId}
            </pre>
            <p>
              Once created, dbt Cloud will provide you with a Secret Token.
              Please provide the Secret Token below. You must also provide
              Whetstone with a read-only service token to dbt Cloud's API to
              allow Whetstone to fetch job artifacts upon completion.
            </p>
            <form onSubmit={handleWebhookSubmit}>
              <fieldset
                style={{
                  maxWidth: "35em",
                  margin: "2em auto",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Input
                  type="password"
                  id="webhookSecretToken"
                  name="Secret Token"
                  label="Webhook Secret Token:"
                  value={webhookAuthorization}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setWebhookAuthorization(event.target.value);
                  }}
                />
                <Input
                  type="password"
                  id="serviceToken"
                  name="Service Token"
                  label="dbt Cloud Service Token:"
                  value={webhookServiceToken}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setWebhookServiceToken(event.target.value);
                  }}
                />

                <Button
                  size="tiny"
                  label={"Save"}
                  primary
                  style={{ maxWidth: "5em", margin: "0 auto" }}
                />
                {!!webhookMutationStatus ? (
                  <LoadingIndicator
                    status={webhookMutationStatus}
                    messages={{
                      success: "Webhook Saved",
                      loading: "Saving Webhook",
                      error: "Error!",
                    }}
                  />
                ) : undefined}
              </fieldset>
            </form>
          </Section>
          <StyledHR />
          <Section>
            <h2>Delete {project.name}</h2>
            <p>
              You can delete this project at any time by pressing the Delete
              button below.
            </p>
            <Alert>
              Warning. This operation is destructive and will result in
              information loss.
            </Alert>
            <DeleteDialog
              project={project}
              open={deleteDialogOpen}
              setOpen={(open: boolean) => setDeleteDialog(open)}
            />
            <div style={{ textAlign: "center" }}>
              <Button
                size="tiny"
                label={`Delete ${project.name}`}
                onClick={() => setDeleteDialog(true)}
              ></Button>
            </div>
          </Section>
        </div>
      ) : undefined}
    </DashboardPageContainer>
  );
};

export default EditProjectPage;
