import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.css";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

// Pages
import Landing from "./pages/landing/index";
import Login from "./pages/login/index";
import DashboardPage from "./pages/dashboard/index";
import CreateAccountPage from "./pages/dashboard/accountCreation";
import ProjectPage from "./pages/projects";
import AnalysisPage from "./pages/analyses";
import DemoPage from "./pages/demo/DemoRuns";

import { getToken, setToken } from "./utilities/api";
import LogoutPage from "./pages/logout";
import DemoAnalysisPage from "./pages/demo/DemoAnalyses";
import RegisterPage from "./pages/register";
import { Footer } from "./components/footer/footer";
import EditProjectPage from "./pages/projects/edit";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Routes>
        <Route path="/" element={<App />}>
          <Route
            path=""
            element={
              <div className="landingWrapper">
                <Landing />
                <Footer />
              </div>
            }
          />
          <Route path="logout" element={<LogoutPage />} />
          <Route path="demo" element={<DemoPage />}>
            <Route path=":correlationId" element={<DemoPage />}></Route>
          </Route>
          <Route
            path="demo/:correlationId/analyses/:demoRunId"
            element={<DemoAnalysisPage />}
          ></Route>
          <Route
            path="login"
            element={<Login setToken={setToken} getToken={getToken} />}
          />
          <Route path="register" element={<RegisterPage />} />
          <Route path="accounts" element={<DashboardPage />}>
            <Route path=":accountId" element={<DashboardPage />}></Route>
          </Route>
          <Route path="new_account" element={<CreateAccountPage />}></Route>
          <Route
            path="accounts/:accountId/projects/:projectId"
            element={<ProjectPage />}
          />
          <Route
            path="accounts/:accountId/projects/:projectId/edit"
            element={<EditProjectPage />}
          />
          <Route
            path="accounts/:accountId/projects/:projectId/analyses/:runId"
            element={<AnalysisPage />}
          />
          <Route
            path="*"
            element={
              <main style={{ padding: "1rem" }}>
                <p>There's nothing here!</p>
              </main>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
