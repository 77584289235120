import Graph from "graphology";
import { bfsFromNode } from "graphology-traversal/bfs";
import { colors } from "./style";
import { v4 as uuidv4 } from "uuid";
import {
  Severity,
  severityColors,
  severityLevels,
  ResultGroup,
} from "./api/results";

export const baseURL = `${process.env.REACT_APP_API_URL}`;

export interface AccessToken {
  token: string;
  type: string;
}

class MissingTokenError extends Error {
  constructor(message: string) {
    super(message); // (1)
    this.name = "MissingTokenError"; // (2)
  }
}

export const setToken = (userToken: AccessToken) => {
  localStorage.setItem("token", JSON.stringify(userToken));
};

export const getToken = () => {
  const tokenString = localStorage.getItem("token");

  if (tokenString === null) {
    throw new MissingTokenError("Token not currently set.");
  }

  const userToken = JSON.parse(tokenString);
  return userToken;
};

export const clearToken = () => {
  localStorage.removeItem("token");
};

export const setCorrelationId = (correlationId: string) => {
  localStorage.setItem("correlationId", correlationId);
};

export const getCorrelationId = () => {
  const correlationId = localStorage.getItem("correlationId");
  if (correlationId === null) {
    let newCorrelationId = uuidv4();
    setCorrelationId(newCorrelationId);
    return newCorrelationId;
  }

  return correlationId;
};

export interface Credentials {
  username: string;
  password: string;
}

export function loginUser(credentials: Credentials): Promise<AccessToken> {
  let formBody =
    "username=" +
    encodeURIComponent(credentials.username) +
    "&password=" +
    encodeURIComponent(credentials.password);

  return fetch(baseURL + "/auth/token", {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: formBody,
  }).then((response) => {
    if (response?.ok) {
      return response.json();
    } else if (response.status === 401) {
      throw new Error("Authentication was unsuccessful.");
    } else if (response.status === 500) {
      throw new Error("Internal server error.");
    }
  });
}

export interface Account {
  id: number;
  name: string;
  plan: string;
  status: string;
  // TODO: Update to have real datetime types
  created_at: string;
  updated_at: string;
}

export function fetchAccountbyId({
  accountId,
}: {
  accountId: number;
}): Promise<Account> {
  return fetch(baseURL + `/accounts/${accountId}`, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + getToken().access_token,
    },
  })
    .then((response) => response.json())
    .then((response) => response.data);
}

interface NewAccount {
  name: string;
}

export function createAccountForUser({ name }: NewAccount): Promise<Account> {
  let token = getToken();

  if (!token) {
    throw new Error("API token not set.");
  }

  return fetch(baseURL + `/users/me/accounts`, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + getToken().access_token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      name: name,
      plan: "team",
      status: "active",
    }),
  })
    .then((response) => response.json())
    .then((accountResponse) => accountResponse.data);
}

export interface User {
  email: string;
  accounts: Account[];
}

export function fetchCurrentUser(): Promise<User> {
  let token = getToken();

  if (!token) {
    throw new Error("API token not set.");
  }

  return fetch(baseURL + `/users/me`, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + getToken().access_token,
    },
  })
    .then((response) => response.json())
    .then((userResponse) => userResponse.data);
}

export enum ErrorTypes {
  UNAURTHORIZED = "UnauthorizedError",
}

export interface GraphData {
  directed: boolean;
  multigraph: boolean;
  graph: object;
  nodes: {
    id: string;
    position: number[];
  }[];
  links: {
    source: string;
    target: string;
  }[];
}

export function graphFromNodeJSON(
  nodeOfInterest: string,
  graphData: GraphData,
  severity?: Severity
) {
  let color = severity ? severityColors[severity] : colors.primary;

  let modifiedGraph = {
    attributes: {},
    nodes: graphData.nodes.map((node) => {
      return {
        key: node.id,
        attributes: {
          label: node.id,
          severity: undefined,
          color: node.id === nodeOfInterest ? color : "#b0b0b0",
          size: node.id === nodeOfInterest ? 5 : 3,
          x: node.position[0],
          y: node.position[1],
        },
      };
    }),
    edges: graphData.links.map((edge) => {
      return {
        ...edge,
        attributes: {
          color: "#e0e0e0",
        },
      };
    }),
  };

  const graph = new Graph();
  graph.import(modifiedGraph);

  bfsFromNode(graph, nodeOfInterest, function (node, attr, depth) {
    graph.forEachOutEdge(node, (edge) => {
      graph.setEdgeAttribute(edge, "color", colors.tertiary);
    });
    if (node === nodeOfInterest) return false;

    // graph.setNodeAttribute(node, 'color', colors.black);
  });

  return graph;
}

export function fetchGraph({
  graphUrl,
  resultGroups,
}: {
  graphUrl: string;
  resultGroups?: ResultGroup[];
}): Promise<Graph> {
  if (resultGroups === undefined)
    throw new Error("Results not provided to graph fetch");

  let resultPriorities = resultGroups.reduce(
    (reduceResult: { [key: string]: Severity }, item: ResultGroup) => {
      if (
        reduceResult.hasOwnProperty(item.entity) &&
        severityLevels[reduceResult[item.entity]] <=
          severityLevels[item.results[0].severity]
      ) {
        return reduceResult;
      }

      reduceResult[item.entity] = item.results[0].severity;
      return reduceResult;
    },
    {}
  );

  return fetch(graphUrl, {
    method: "GET",
  })
    .then((response) => response.json())
    .then((graphData: GraphData) => {
      let modifiedGraph = {
        attributes: {},
        nodes: graphData.nodes.map((node) => {
          return {
            key: node.id,
            attributes: {
              label: node.id,
              severity: resultPriorities[node.id],
              color: resultPriorities.hasOwnProperty(node.id)
                ? colors.primary //severityColors[resultPriorities[node.id]]
                : "#b0b0b0",
              size: resultPriorities.hasOwnProperty(node.id) ? 3 : 2,
              x: node.position[0],
              y: node.position[1],
            },
          };
        }),
        edges: graphData.links.map((edge) => {
          return {
            ...edge,
            attributes: {
              color: "#e0e0e0",
            },
          };
        }),
      };

      const graph = new Graph();
      graph.import(modifiedGraph);

      Object.keys(resultPriorities).forEach((nodeOfInterest: string) => {
        bfsFromNode(graph, nodeOfInterest, function (node, attr, depth) {
          graph.forEachOutEdge(node, (edge) => {
            graph.setEdgeAttribute(edge, "color", colors.tertiary);
          });
        });
      });

      return graph;
    });
}
