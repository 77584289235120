import React from "react";
import styled from "styled-components";
import { DateTime, Duration } from "luxon";

import { Run } from "../../utilities/api/runs";
import { Button } from "../../components/button/Button";

interface StatusTextProps {
  status: "success" | "error" | "queued";
}

const StatusText = styled.span<StatusTextProps>`
  text-transform: capitalize;

  color: ${(props) =>
    props.status === "success"
      ? "#358b37"
      : props.status === "queued"
      ? "#dbd5d3"
      : "#c42828"};

  border-bottom: 1px solid
    ${(props) =>
      props.status === "success"
        ? "#358b37"
        : props.status === "queued"
        ? "#dbd5d3"
        : "#c42828"};
`;

const TimeCard = styled.table`
  font-size: small;
  margin: 1em 0;
  border-collapse: collapse;
`;

interface RunMetadataCardProps {
  run: Run;
  retryHandler?: () => void;
}
const RunMetadataCard: React.FC<RunMetadataCardProps> = ({
  run,
  retryHandler,
}) => {
  return (
    <div>
      <p>
        Status:{" "}
        <StatusText status={run.status}>
          {run.status === "success" ? "Completed" : run.status}
        </StatusText>
      </p>
      <TimeCard>
        <tbody>
          <tr>
            <td>Triggered At:</td>
            <td>
              {DateTime.fromISO(run.created_at).toLocaleString(
                DateTime.DATETIME_FULL_WITH_SECONDS
              )}
            </td>
          </tr>
          <tr>
            <td>Started At:</td>
            <td>
              <abbr
                title={`Queued for ${Duration.fromObject({
                  seconds: run.queued_duration,
                }).toHuman()}`}
              >
                {run.started_at
                  ? DateTime.fromISO(run.started_at).toLocaleString(
                      DateTime.DATETIME_FULL_WITH_SECONDS
                    )
                  : undefined}
              </abbr>
            </td>
          </tr>
          <tr>
            <td>Finished At:</td>
            <td>
              <abbr
                title={`Ran for ${Duration.fromObject({
                  seconds: run.run_duration,
                }).toHuman()}`}
              >
                {run.finished_at
                  ? DateTime.fromISO(run.finished_at).toLocaleString(
                      DateTime.DATETIME_FULL_WITH_SECONDS
                    )
                  : undefined}
              </abbr>
            </td>
          </tr>
        </tbody>
      </TimeCard>

      <div style={{ display: "flex", gap: "1em", fontSize: "small" }}>
        {run.manifest_url ? (
          <a href={`${run.manifest_url}`} download>
            manifest.json
          </a>
        ) : undefined}
        {run.run_results_url ? (
          <a href={`${run.run_results_url}`} download>
            run_results.json
          </a>
        ) : undefined}
      </div>
      {!!retryHandler ? (
        <p>
          {run.status !== "queued" ? (
            <Button size="tiny" label="Retry" onClick={retryHandler} />
          ) : undefined}
        </p>
      ) : undefined}
    </div>
  );
};

export default RunMetadataCard;
