import styled, { css } from "styled-components";
import { colors } from "../../utilities/style";

interface AlertDivProps {
  color: string;
}

const AlertDiv = styled.div<AlertDivProps>`
  margin: 2em;
  padding: 0 1em;

  ${(props) =>
    props.color &&
    css`
      border: 1px solid ${props.color};
      color: ${props.color};
      background-color: ${props.color}05;
    `}

  p {
    filter: brightness(60%);
  }
`;

interface AlertProps {
  type?: "alert" | "warning" | "info" | "success";
}

const Alert: React.FC<AlertProps> = ({ children, type }) => {
  const colorMap = {
    alert: colors.primary,
    warning: colors.tertiary,
    info: colors.gray,
    success: colors.secondary,
  };

  return (
    <AlertDiv color={colorMap[type || "alert"]}>
      <p>{children}</p>
    </AlertDiv>
  );
};

export default Alert;
