import React, { useState } from "react";
import Graph from "graphology";

import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { useQuery, useQueryClient, useMutation } from "react-query";

import { Account, ErrorTypes, fetchAccountbyId } from "../../utilities/api";
import { fetchResultsForRun, ResultGroup } from "../../utilities/api/results";
import { Run, fetchRun, retryRun } from "../../utilities/api/runs";

import { fetchGraph } from "../../utilities/api";

import ResultsPage from "../../components/results/resultsPage";
import { fetchProjectForAccount, Project } from "../../utilities/api/projects";
import { APIResponse } from "../../utilities/api/index";

const AnalysisPage: React.FC = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  let [searchParams] = useSearchParams();
  let pageValue = searchParams.get("page") || "1";

  const [filters, setFilters] = useState({});

  const onFilterChange = (filterChange: { [index: string]: string[] }) => {
    console.log(filterChange);

    let newFilters: { [index: string]: string[] } = {
      ...filters,
      ...filterChange,
    };

    Object.keys(newFilters).forEach((key) =>
      newFilters[key].length === 0 ? delete newFilters[key] : {}
    );

    setFilters(newFilters);
  };

  let page = parseInt(pageValue);

  const mutateRuns = useMutation(retryRun, {
    onSuccess: (data) => {
      // Invalidate and refetch
      queryClient.invalidateQueries("projects");
      navigate(
        `/accounts/${data.account_id}/projects/${data.project_id}/analyses/${data.id}`
      );
    },
  });

  const { accountId, projectId, runId } = useParams();

  const accountQuery = useQuery<Account, Error>(
    ["accounts", { accountId }],
    ({ queryKey }: any) =>
      fetchAccountbyId({ accountId: queryKey[1].accountId }),
    {
      enabled: !!accountId,
    }
  );

  const projectQuery = useQuery<Project, Error>(
    ["project", { accountId, projectId }],
    ({ queryKey }: any) =>
      fetchProjectForAccount({
        accountId: queryKey[1].accountId,
        projectId: queryKey[1].projectId,
      }),
    {
      enabled: !!accountId,
    }
  );

  const runQuery = useQuery<Run, Error>(
    ["runs", { accountId, runId }],
    ({ queryKey }: any) =>
      fetchRun({
        accountId: queryKey[1].accountId,
        runId: queryKey[1].runId,
      }),
    {
      // The query will not execute until the userId exists

      enabled: !!accountId && !!runId,
      refetchOnWindowFocus: false,
      refetchInterval: (data) => {
        if (data === undefined) {
          return 5000;
        }
        if (data.status === "success" || data.status === "error") {
          return false;
        }
        return 5000;
      },
    }
  );

  const resultQuery = useQuery<APIResponse<ResultGroup[]>, Error>(
    ["runResults", { accountId, runId, page, filters }],
    ({ queryKey }: any) =>
      fetchResultsForRun({
        accountId: queryKey[1].accountId,
        runId: queryKey[1].runId,
        page: queryKey[1].page,
        filters: queryKey[1].filters,
      }),
    {
      // The query will not execute until the userId exists
      keepPreviousData: true,
      enabled: !!accountId && !!runId && runQuery.data?.status === "success",
    }
  );

  const graphQuery = useQuery<Graph, Error>(
    ["graphs", { graphUrl: runQuery.data?.graph_url }],
    ({ queryKey }: any) =>
      fetchGraph({
        graphUrl: queryKey[1].graphUrl,
        resultGroups: resultQuery.data?.data,
      }),
    {
      // The query will not execute until the userId exists
      keepPreviousData: true,
      enabled:
        !!runQuery.isSuccess &&
        runQuery.data.status === "success" &&
        !!resultQuery.isSuccess &&
        !!resultQuery.data,
    }
  );

  // If there is an error getting the run, then redirect to the home page
  if (
    resultQuery.error &&
    resultQuery.error.name === ErrorTypes.UNAURTHORIZED
  ) {
    navigate("/");
  }

  if (!runId) {
    console.error("Run ID not known!");
    return <></>;
  }

  return (
    <ResultsPage
      breadcrumbs={[
        { name: `${accountQuery.data?.name}`, url: `/accounts/${accountId}` },
        {
          name: `${projectQuery.data?.name}`,
          url: `/accounts/${accountId}/projects/${projectId}`,
        },
      ]}
      projectQuery={projectQuery}
      runId={runId}
      runQuery={runQuery}
      resultQuery={resultQuery}
      graphQuery={graphQuery}
      mutateRuns={mutateRuns}
      mutateRunPayload={{
        accountId: runQuery.data?.account_id,
        runId: runQuery.data?.id,
      }}
      runUrl={`/accounts/${accountId}/projects/${projectId}/analyses/${runId}`}
      onFilterChange={onFilterChange}
    />
  );
};

export default AnalysisPage;
