import React, { useState } from "react";
// import styled from 'styled-components';
import { useMutation } from "react-query";

import { useQueryClient } from "react-query";
import { loginUser, setToken } from "../../utilities/api";
import { RegistrationForm } from "../../components/auth/registration";
import { useNavigate } from "react-router-dom";
import { registerUser } from "../../utilities/api/auth";

const RegisterPage: React.FC = () => {
  const navigate = useNavigate();

  const [username, setUserName] = useState<string | undefined>();
  const [password, setPassword] = useState<string | undefined>();

  const queryClient = useQueryClient();

  const loginMutation = useMutation(loginUser, {
    onSuccess: (token) => {
      // Invalidate and refetch
      setToken(token);
      navigate("/accounts");
    },
  });

  const mutation = useMutation(registerUser, {
    onSuccess: (user) => {
      if (!username || !password) {
        return;
      }
      queryClient.invalidateQueries("user");
      queryClient.setQueryData(["user"], user);

      loginMutation.mutate({
        username,
        password,
      });
    },
  });

  const handleSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!username || !password) {
      return;
    }

    mutation.mutate({ username, password });
  };

  return (
    <RegistrationForm
      handleSubmit={handleSubmit}
      setUserName={setUserName}
      setPassword={setPassword}
    ></RegistrationForm>
  );
};

export default RegisterPage;
