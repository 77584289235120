import styled, { css } from "styled-components";
import { colors } from "../../utilities/style";

interface LandingSectionProps {
  /**
   * What thickness should we use for the section?
   */
  thickness?: "thin" | "normal" | "thick";

  /**
   * How should we render children?
   */
  flexDirection?: "row" | "row-reverse" | "column" | "column-reverse";

  width?: "normal" | "wide";
}

export const LandingSection = styled.div<LandingSectionProps>`
  font-family: "Roboto Mono", monospace;

  margin: ${(props) =>
      props.thickness === "thin"
        ? "5vh"
        : props.thickness === "thick"
        ? "20vh"
        : "10vh"}
    auto;

  ${(props) =>
    props.width === "wide"
      ? css`
          margin: 15vh -10em;
          padding: 15vh 10em;
          overflow: hidden;
        `
      : undefined}

  @media only screen and (max-width: 720px) {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 1em;

    margin: 10vh auto;
    padding: 0;

    ${(props) =>
      props.width === "wide"
        ? css`
            margin: 10vh -2em;
            padding: 10vh 2em;
            overflow: hidden;
          `
        : undefined}
  }

  ${(props) =>
    props.flexDirection
      ? css`
          display: flex;
          align-items: center;
          gap: 5em;
          flex-direction: ${props.flexDirection};

          h2 {
            flex-grow: 1;
          }
        `
      : undefined}
`;

export const LandingContainer = styled.div`
  max-width: 70em;
  margin: 10em auto;

  h1 {
    font-size: 3em;
  }

  h2 {
    font-size: 2em;
    font-weight: 400;
  }

  p {
    font-size: 1.5em;
    color: #333;
  }
`;

export const CTAButtonContainer = styled.div`
  display: flex;
  width: 100%;

  align-content: space-around;
  justify-content: center;
  button {
    margin: 1em;
  }
`;

export const Highlight = styled.span`
  color: ${colors.primary};
`;
