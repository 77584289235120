import Graph from "graphology";

import React, { createContext, useState, useCallback } from "react";

interface IGraohCacheContext {
  getGraph: (key: string) => GraphCacheRecord | undefined;
  addGraph: (key: string, graph: Graph, hasLayout: boolean) => void;
}

interface GraphCacheRecord {
  graph: Graph;
  hasLayout?: boolean;
}

interface GraphCache {
  [key: string]: GraphCacheRecord;
}

const defaultState: IGraohCacheContext = {
  getGraph: (key: string) => undefined,
  addGraph: (key: string, graph: Graph) => {},
};

export const GraphCacheContext =
  createContext<IGraohCacheContext>(defaultState);

export const GraphCacheProvider: React.FC = ({ children }) => {
  const [cache, setCache] = useState<GraphCache>({});

  const addGraph = useCallback(
    (key: string, graph: Graph, hasLayout: boolean) => {
      setCache((oldCache) => ({
        ...oldCache,
        [key]: { graph, hasLayout },
      }));
    },
    []
  );

  const getGraph = (key: string): GraphCacheRecord | undefined => {
    if (cache.hasOwnProperty(key)) {
      return cache[key];
    }

    return undefined;
  };

  return (
    <GraphCacheContext.Provider
      value={{
        addGraph,
        getGraph,
      }}
    >
      {children}
    </GraphCacheContext.Provider>
  );
};
