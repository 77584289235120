import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

interface PaginatorProps {
  page: number;
  totalPages: number;
}

const Paginator: React.FC<
  PaginatorProps & React.HTMLAttributes<HTMLDivElement>
> = ({ page, className, totalPages }) => {
  let previousPages = [];
  let nextPages = [];

  for (var i = page - 1; i > 0 && page - i < 5; i--) {
    previousPages.push(
      <Link key={i} to={`?page=${i}`}>
        {i}
      </Link>
    );
  }
  if (page - 4 > 1) {
    previousPages.push(
      <Link key={1} to={`?page=${1}`}>
        {1}
      </Link>
    );
  }
  for (var j = 1; j < 5 && page + j <= totalPages; j++) {
    nextPages.push(
      <Link key={page + j} to={`?page=${page + j}`}>
        {page + j}
      </Link>
    );
  }
  if (page + 4 < totalPages) {
    nextPages.push(
      <Link key={totalPages} to={`?page=${totalPages}`}>
        {totalPages}
      </Link>
    );
  }

  return (
    <div className={className}>
      {page > 1 ? (
        <Link key="previous" to={`?page=${page - 1}`}>
          Previous
        </Link>
      ) : undefined}
      {previousPages.reverse().map((item) => item)}
      <span>{page}</span>
      {nextPages.map((item) => item)}
      {page < totalPages ? (
        <Link key="next" to={`?page=${page + 1}`}>
          Next
        </Link>
      ) : undefined}
    </div>
  );
};

const StyledPaginator = styled(Paginator)`
  margin: 2em auto;
  text-align: center;

  display: flex;
  justify-content: center;

  & > a {
    margin: 0 1em;
  }

  & > span {
    margin: 0 1em;
  }
`;

export default StyledPaginator;
