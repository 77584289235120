import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export type LinkDefinition = {
  name: string;
  url: string;
};

export interface BreadcrumbProps {
  links: LinkDefinition[];
}

const BreadcrumbSpan = styled.span`
  font-weight: 100;
  font-size: small;
`;

export const Breadcrumbs: React.FC<BreadcrumbProps> = ({ links }) => {
  return (
    <div>
      {links.map((link, index) => {
        return (
          <BreadcrumbSpan key={`breadcrumb${index}`}>
            <Link to={link.url}>{link.name}</Link>{' '}
            {index < links.length - 1 ? ' > ' : undefined}
          </BreadcrumbSpan>
        );
      })}
    </div>
  );
};
