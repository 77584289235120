/*
    A metadata react provider to ensure that all children
    have access to Metadata.
*/

import React, { createContext, useState } from "react";

export interface NodeReference {
  [index: string]: string[];
}

export interface Manifest {
  metadata: object;
  nodes: any;
  sources: object;
  macros: object;
  docs: object;
  exposures: object;
  metrics: object;
  selectors: object;
  disabled: object;
  parent_map: NodeReference;
  child_map: NodeReference;
}

export interface RunResult {
  status: string;
  timing: Array<object>;
  thread_id: string;
  execution_time: number;
  adapter_response: object;
  message?: string;
  failures?: number;
  unique_id: string;
}

export interface RunResults {
  metadata: object;
  results: Array<RunResult>;
  elapsed_time: object;
  args: object;
}

interface IMetadataContext {
  manifest?: Manifest;
  runResults?: RunResults;
  setManifest?: (newManifest: Manifest) => void;
  setRunResults?: (newRunResults: RunResults) => void;
}

const defaultState: IMetadataContext = {};

export const MetadataContext = createContext<IMetadataContext>(defaultState);

export const MetadataProvider: React.FC = ({ children }) => {
  const [manifest, setManifest] = useState(defaultState.manifest);
  const [runResults, setRunResults] = useState(defaultState.runResults);

  return (
    <MetadataContext.Provider
      value={{
        manifest,
        runResults,
        setManifest,
        setRunResults,
      }}
    >
      {children}
    </MetadataContext.Provider>
  );
};
