import React, { useState } from "react";

import { useMutation, useQueryClient } from "react-query";

import {
  submitRun,
  RunConfiguration,
  DemoRunConfiguration,
} from "../../utilities/api/runs";
import { FileUpload } from "../fileUploader";
import { Button } from "../button/Button";
import { ButtonNav, Dialog } from "../dialog/dialog";

interface UploadDialogProps {
  configuration: RunConfiguration | DemoRunConfiguration;
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const UploadDialog: React.FC<UploadDialogProps> = ({
  configuration,
  open,
  setOpen,
}) => {
  const queryClient = useQueryClient();
  const [manifestFile, setManifestFile] = useState();
  const [runResultsFile, setRunResultsFile] = useState();

  const mutation = useMutation(submitRun, {
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries("runs");
    },
  });

  const handleSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!manifestFile || !runResultsFile) {
      return;
    }

    mutation.mutate({
      configuration,
      manifest: manifestFile,
      runResults: runResultsFile,
    });

    setOpen(false);
  };

  return (
    <Dialog
      id="uploadDialog"
      setOpen={setOpen}
      onSubmit={handleSubmit}
      open={open}
    >
      <div>
        <h2>Upload Artifacts</h2>
        <p>
          You can upload <code>manifest.json</code> and{" "}
          <code>run_results.json</code> artifiacts to trigger a new analysis.
        </p>
        <form onSubmit={handleSubmit} style={{ margin: "2em 1em 0 1em" }}>
          <FileUpload
            setFile={setManifestFile}
            name="manifest"
            label="Manifest File"
          />
          <FileUpload
            setFile={setRunResultsFile}
            name="runResults"
            label="Run Results File"
          />
          <ButtonNav>
            <Button
              label="Cancel"
              type="button"
              onClick={() => setOpen(false)}
            />
            <Button primary label="Submit" type="submit" />
          </ButtonNav>
        </form>
      </div>
    </Dialog>
  );
};
