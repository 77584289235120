import React, { ReactNode, useState } from "react";
import styled from "styled-components";

import { Button } from "../../components/button/Button";
import { Input } from "../../components/forms/forms";
import { AccessToken, loginUser } from "../../utilities/api";

import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import { colors } from "../../utilities/style";

const LoginContainer = styled.div`
  font-family: "Roboto Mono", monospace;
  display: flex;
  flex-direction: column;
  justify-content: center;

  max-width: 25em;
  textalign: center;

  padding: 1em;
  margin: auto;
`;

interface LoginFormProps {
  setToken: (accessToken: AccessToken) => void;
  children?: ReactNode;
}

const ErrorMessage = styled.div`
  border: 1px dashed ${colors.primary};
  color: ${colors.primary};
  margin: 1em auto 0 auto;
  padding: 1em;
  font-size: small;
`;

export const LoginForm: React.FC<LoginFormProps> = ({ setToken, children }) => {
  const [username, setUserName] = useState<string | undefined>();
  const [password, setPassword] = useState<string | undefined>();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const mutation = useMutation(loginUser, {
    onSuccess: (token) => {
      // Invalidate and refetch
      queryClient.invalidateQueries("user");
      setToken(token);
      setErrorMessage(undefined);
      navigate("/accounts");
    },
    onError: (error: Error) => {
      setErrorMessage(error.message);
    },
  });

  const handleSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!username || !password) {
      return;
    }

    mutation.mutate({
      username,
      password,
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <LoginContainer>
        <p
          style={{
            textAlign: "center",
            margin: "2em 1em",
          }}
        >
          Sign in to your account
        </p>
        <Input
          type="email"
          id="usernameInput"
          label="Email:"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setUserName(e.target.value)
          }
        />
        <Input
          type="password"
          id="passwordInput"
          label="Password:"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setPassword(e.target.value)
          }
        />
        {!!errorMessage ? (
          <ErrorMessage>{errorMessage}</ErrorMessage>
        ) : undefined}
        <Button
          type="submit"
          label="Login"
          size="medium"
          primary
          style={{ margin: "2em auto" }}
        />
      </LoginContainer>
    </form>
  );
};
