import React from "react";
import styled, { css } from "styled-components";
import { colors } from "../../utilities/style";

interface ButtonProps {
  /**
   * Is this the principal call to action on the page?
   */
  primary?: boolean;
  /**
   * What background color to use
   */
  backgroundColor?: string;
  /**
   * How large should the button be?
   */
  size?: "tiny" | "small" | "medium" | "large";
  /**
   * Button contents
   */
  label: string;
  /**
   * Optional click handler
   */
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;

  type?: "button" | "submit" | "reset" | undefined;

  style?: object;
}

interface StyledButtonProps {
  primary: boolean;
  backgroundColor?: string;
  size: "tiny" | "small" | "medium" | "large";
}

const StyledButton = styled.button<StyledButtonProps>`
  font-family: "Roboto Mono", monospace;
  font-weight: 700;
  border: 0;
  cursor: pointer;
  display: inline-block;
  line-height: 1;

  ${(props) =>
    props.size === "small"
      ? css`
          font-size: 12px;
          padding: 10px 16px;
        `
      : props.size === "tiny"
      ? css`
          font-size: 0.7em;
          padding: 1em 14px;
        `
      : props.size === "medium"
      ? css`
          font-size: 14px;
          padding: 11px 20px;
        `
      : css`
          font-size: 16px;
          padding: 12px 24px;
        `}

  ${(props) =>
    props.primary
      ? css`
          color: white;
          background-color: ${colors.primary};
        `
      : css`
          color: ${colors.primary};
          background-color: transparent;
          box-shadow: ${colors.primary} 0px 0px 0px 1px inset;
        `}

  ${(props) =>
    props.backgroundColor
      ? css`
          background-color: ${props.backgroundColor};
        `
      : undefined}
`;

/**
 * Primary UI component for user interaction
 */
export const Button = ({
  primary = false,
  size = "medium",
  backgroundColor,
  label,
  ...props
}: ButtonProps) => {
  return (
    <StyledButton primary={primary} size={size} {...props}>
      {label}
    </StyledButton>
  );
};

interface StyledLinkButtonProps {
  size: "tiny" | "small" | "medium" | "large";
}

const StyledLinkButton = styled.button<StyledLinkButtonProps>`
  font-family: "Roboto Mono", monospace;
  font-weight: 500;
  border: 0;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  margin: 0;
  padding: 0;

  ${(props) =>
    props.size === "small"
      ? css`
          font-size: 0.9em;
        `
      : props.size === "tiny"
      ? css`
          font-size: 0.8em;
        `
      : props.size === "medium"
      ? css`
          font-size: 1.25em;
        `
      : css`
          font-size: 1em;
        `}
`;

interface LinkButtonProps {
  label: string;
  size: "tiny" | "small" | "medium" | "large";
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  type?: "button" | "submit" | "reset" | undefined;
}

export const LinkButton = ({ label, size, ...props }: LinkButtonProps) => {
  return (
    <StyledLinkButton size={size} {...props}>
      {label}
    </StyledLinkButton>
  );
};
