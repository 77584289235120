import React from "react";
import ReactMarkdown from "react-markdown";
import SyntaxHighlighter from "react-syntax-highlighter";
import { xonokai } from "react-syntax-highlighter/dist/esm/styles/prism";

import { Button } from "../../components/button/Button";
import {
  LandingSection,
  LandingContainer,
  CTAButtonContainer,
  Highlight,
} from "../../components/marketing/landing";
import { MarketingGraph } from "../../components/marketing/marketingGraph";
import { TypedText } from "../../components/marketing/typedText";
import { colors } from "../../utilities/style";

const Landing: React.FC = () => {
  return (
    <LandingContainer>
      <LandingSection thickness="thick">
        <h1>
          It's easy for dbt projects to become{" "}
          <TypedText
            wordList={[
              "messy",
              "complex",
              "slow",
              "expensive",
              "spaghettified",
              "unruly",
              "gnarled",
              "scary",
              "untamed",
            ]}
          />
        </h1>
        <p>
          <Highlight>Whetstone</Highlight> uses your dbt project's metadata to
          keep your graph sharp and your team efficient.
        </p>
        <CTAButtonContainer>
          <a href="register">
            <Button label={"Sign Up"} primary size={"large"} />
          </a>
          <a href="demo">
            <Button label={"Try It Out"} size={"large"} />
          </a>
        </CTAButtonContainer>
      </LandingSection>
      <LandingSection
        width="wide"
        style={{
          backgroundColor: colors.primary,
          color: colors.white,
        }}
      >
        <div style={{ display: "flex", gap: "2em" }}>
          <div style={{ flexBasis: "60%" }}>
            <h2>How does it work?</h2>
            <ol style={{ fontSize: "1.2em" }}>
              <li>Create a Whetstone project.</li>
              <li>
                Setup a dbt Cloud Webhook integration or upload your dbt
                project's{" "}
                <a
                  className="invertedLink"
                  href="https://docs.getdbt.com/reference/artifacts/dbt-artifacts"
                >
                  artifacts
                </a>{" "}
                manually.
              </li>
              <li>View your project's Whetstone results.</li>
            </ol>
          </div>
          <div style={{ flexGrow: 1 }}>
            <iframe
              title="Webhook Demo"
              src="https://www.loom.com/embed/174859abe5314d9eb4ffa86587eaee99"
              frameBorder="0"
              allowFullScreen
              style={{
                width: "100%",
                height: "100%",
              }}
            ></iframe>
          </div>
        </div>
      </LandingSection>
      <LandingSection>
        <p
          style={{
            // textAlign: "justify",
            // textJustify: "inter-character",
            margin: "0.75em 0 0 0",
          }}
        >
          <Highlight>Automatically detect</Highlight> inefficient and
          error-prone transformation patterns, and{" "}
          <Highlight>provide guidance</Highlight> for effective refactoring.
        </p>
        <MarketingGraph />
      </LandingSection>
      <LandingSection flexDirection="row">
        <p>
          See the impact of <Highlight>each model</Highlight> on the performance
          and accuracy of your entire dbt project.
        </p>
        <img
          src="/graph_screenshot.png"
          alt="Screenshot of a project Graph indicating where issues in the project lie and the overal impact on the graph."
          width="100%"
        />
      </LandingSection>
      <LandingSection flexDirection="row">
        {/* <img src="https://dummyimage.com/300" alt="dummy" /> */}
        <ReactMarkdown
          className="mobileHidden"
          components={{
            code({ node, inline, className, style, children, ...props }) {
              const match = /language-(\w+)/.exec(className || "");
              return !inline && match ? (
                <SyntaxHighlighter
                  children={String(children).replace(/\n$/, "")}
                  style={xonokai}
                  language={match[1]}
                  PreTag="pre"
                  showLineNumbers={true}
                  {...props}
                />
              ) : (
                <code className={className} {...props}>
                  {children}
                </code>
              );
            },
          }}
        >
          {
            '~~~bash\ncurl -X POST \\  \n\thttps://whetstone.nicholasyager.com/api/account/1/runs \\\n\t-d { "manifest": ... } \n~~~'
          }
        </ReactMarkdown>
        <p>
          Integrate with your dbt orchestration tools for{" "}
          <Highlight>continuous analysis</Highlight>.
          <br />
          {
            // TODO: Wireup the documentation button.
          }
          <a
            href="https://whetstone.nicholasyager.com/api/redoc"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button label={"API Documentation"}></Button>
          </a>
        </p>
      </LandingSection>
    </LandingContainer>
  );
};
export default Landing;
