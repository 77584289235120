import { baseURL, Credentials, User } from "../api";

export function registerUser(credentials: Credentials): Promise<User> {
  return fetch(baseURL + "/users", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: credentials.username,
      password: credentials.password,
    }),
  })
    .then((response) => response.json())
    .then((response) => response.data);
}
