import React, { useState } from "react";
import styled from "styled-components";
import { LinkButton } from "../button/Button";

interface InputProps {
  id: string;
  name?: string;
  label: string;
  value?: any;
  type?: string;
  onChange?: any;
}

const InputLabel = styled.label`
  font-family: "Roboto Mono", monospace;
  font-weight: 300;

  display: flex;
  justify-content: space-between;

  margin: 1em;

  input {
    flex-shrink: 1;
    display: block;
    margin: 0 0 0 2em;
    width: 100%;
  }

  span {
    flex-shrink: 0;
  }

  button {
    margin: 0 0 0 1em;
  }

  box-sizing: border-box;

  *,
  ::after,
  ::before {
    box-sizing: border-box;
  }
`;

export const Input: React.FC<InputProps> = (props) => {
  const [type, setType] = useState(props.type);

  return (
    <InputLabel htmlFor={props.id}>
      <span>{props.label}</span>
      <input
        id={props.id}
        type={props.type === "password" ? type : props.type}
        name={props.name}
        onChange={props.onChange}
        value={props.value}
      />
      {props.type === "password" ? (
        <LinkButton
          type="button"
          label={type === "password" ? "Show" : "Hide"}
          size={"small"}
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();
            let newType = "password";
            if (type === "password") {
              newType = "text";
            }
            setType(newType);
          }}
        />
      ) : undefined}
    </InputLabel>
  );
};
