import { APIResponse } from "./index";
import { baseURL, getToken } from "../api";

export interface RunStatistic {
  statistic: string;
  label: string;
  value: number;
  delta: number;
  invert?: boolean;
  type?: "number" | "percentage";
}

export interface RunStatisticDict {
  [index: string]: RunStatistic;
}

export interface RunMetadata {
  invocation_id?: string;
}

export interface Run {
  id: number | string;
  account_id?: number;
  project_id?: number;
  correlation_id?: string;
  status: "success" | "error" | "queued";

  // TODO: Update to have real datetime types
  created_at: string;
  updated_at: string;

  dequeued_at?: string;
  started_at?: string;
  finished_at?: string;

  duration?: number;
  queued_duration?: number;
  run_duration?: number;

  manifest_url: string;
  run_results_url: string;
  graph_url: string;

  metadata?: RunMetadata;

  statistics?: RunStatisticDict;
}

export function fetchRunsForProject({
  accountId,
  projectId,
  page,
}: {
  accountId: number;
  projectId: number;
  page: number;
}): Promise<APIResponse<Run[]>> {
  let limit = 10;
  let offset = limit * (page - 1);

  return fetch(
    baseURL +
      `/accounts/${accountId}/runs?project_id=${projectId}&limit=${limit}&offset=${offset}&order_by=-created_at`,
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + getToken().access_token,
      },
    }
  ).then((response) => response.json());
}

export function fetchLatestRunForProject({
  accountId,
  projectId,
}: {
  accountId: number;
  projectId: number;
}): Promise<APIResponse<Run[]>> {
  let limit = 1;
  let offset = 0;

  return fetch(
    baseURL +
      `/accounts/${accountId}/runs?project_id=${projectId}&limit=${limit}&offset=${offset}&order_by=-created_at`,
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + getToken().access_token,
      },
    }
  ).then((response) => response.json());
}

export function fetchDemoRuns({
  correlationId,
  page,
}: {
  correlationId: string;
  page: number;
}): Promise<APIResponse<Run[]>> {
  let limit = 10;
  let offset = limit * (page - 1);

  return fetch(
    baseURL +
      `/demo/runs?correlation_id=${correlationId}&limit=${limit}&offset=${offset}&order_by=-created_at`,
    {
      method: "GET",
    }
  ).then((response) => response.json());
}

export function fetchRun({
  accountId,
  runId,
}: {
  accountId: number;
  runId: number;
}): Promise<Run> {
  return fetch(baseURL + `/accounts/${accountId}/runs/${runId}`, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + getToken().access_token,
    },
  })
    .then((response) => response.json())
    .then((response) => response.data);
}

export function fetchDemoRun({ runId }: { runId: number }): Promise<Run> {
  return fetch(baseURL + `/demo/runs/${runId}`, {
    method: "GET",
  })
    .then((response) => response.json())
    .then((response) => response.data);
}

export interface RunConfiguration {
  kind: "Run";
  accountId: number;
  projectId: number;
}

export interface DemoRunConfiguration {
  kind: "DemoRun";
  correlationId: string;
}

export type RunSubmitConfiguration = RunConfiguration | DemoRunConfiguration;

export function submitDemoRun({
  correlationId,
  manifest,
  runResults,
}: {
  correlationId: string;
  manifest: object;
  runResults: object;
}): Promise<Run> {
  let url = baseURL + `/demo/runs`;
  let payload = {
    manifest,
    run_results: runResults,
    correlation_id: correlationId,
  };

  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  })
    .then((response) => response.json())
    .then((response) => response.data);
}

export function submitProjectRun({
  accountId,
  projectId,
  manifest,
  runResults,
}: {
  accountId: number;
  projectId: number;
  manifest: object;
  runResults: object;
}): Promise<Run> {
  let url = baseURL + `/accounts/${accountId}/runs`;
  let payload = {
    project_id: projectId,
    manifest,
    run_results: runResults,
  };
  return fetch(url, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + getToken().access_token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  })
    .then((response) => response.json())
    .then((response) => response.data);
}

export function submitRun({
  configuration,
  manifest,
  runResults,
}: {
  configuration: RunConfiguration | DemoRunConfiguration;
  manifest: object;
  runResults: object;
}): Promise<Run> {
  if (configuration.kind === "Run") {
    return submitProjectRun({
      accountId: configuration.accountId,
      projectId: configuration.projectId,
      manifest,
      runResults,
    });
  } else {
    return submitDemoRun({
      correlationId: configuration.correlationId,
      manifest,
      runResults,
    });
  }
}

export function retryRun({
  accountId,
  runId,
}: {
  accountId: number;
  runId: number;
}): Promise<Run> {
  return fetch(baseURL + `/accounts/${accountId}/runs/${runId}/retry`, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + getToken().access_token,
    },
  })
    .then((response) => response.json())
    .then((response) => response.data);
}

export function retryDemoRun({ runId }: { runId: number }): Promise<Run> {
  return fetch(baseURL + `/demo/runs/${runId}/retry`, {
    method: "POST",
  })
    .then((response) => response.json())
    .then((response) => response.data);
}
