import ReactFlow, {
  Edge,
  Node,
  Position,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from "reactflow";
import { GraphData } from "../../utilities/api";
import { colors } from "../../utilities/style";

import { useEffect } from "react";
import Dagre from "dagre";

import "reactflow/dist/style.css";

interface SubgraphProps {
  id: string;
  graph: GraphData;
  afterRenderCallback: () => void;
}

export const Subgraph: React.FC<SubgraphProps> = ({
  id,
  graph,
  afterRenderCallback,
}) => {
  const { fitView } = useReactFlow();

  const [nodes, setNodes] = useNodesState([]);
  const [edges, setEdges] = useEdgesState([]);

  useEffect(() => {
    const getLayoutedElements = (
      nodes: Node[],
      edges: Edge[],
      options: { direction: string }
    ) => {
      const g = new Dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}));

      g.setGraph({ rankdir: options.direction });

      edges.forEach((edge) => g.setEdge(edge.source, edge.target));
      nodes.forEach((node) => g.setNode(node.id, node));

      Dagre.layout(g);

      return {
        nodes: nodes.map((node) => {
          const { x, y } = g.node(node.id);

          return { ...node, position: { x, y } };
        }),
        edges,
      };
    };

    var initialNodes: Node[] = graph.nodes.map((value) => {
      var node_type = value.id.split(".").at(0);
      var node_name = value.id.split(".").at(-1);

      return {
        id: value.id,
        position: { x: value.position[0], y: value.position[1] },
        data: { label: node_name },
        width: (node_name || "").length * 7,
        height: 15,
        selected: id === value.id,
        draggable: false,
        connectable: false,
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
        type:
          node_type === "source" || node_type === "seed"
            ? "input"
            : node_type === "model"
            ? "default"
            : "output",

        style: {
          width: "auto",
          borderRadius: "0",
          backgroundColor: colors.white,
          borderColor: id === value.id ? colors.primary : colors.black,
          borderStyle: node_type === "source" ? "dashed" : "solid",
          boxShadow:
            id === value.id ? "0 0 0 0.5px " + colors.primary : undefined,
        },
      };
    });

    var initialEdges: Edge[] = graph.links.map((value) => {
      return {
        id: value.source + "-" + value.target,
        ...value,
      };
    });

    const layouted = getLayoutedElements(initialNodes, initialEdges, {
      direction: "LR",
    });

    setNodes([...layouted.nodes]);
    setEdges([...layouted.edges]);

    window.requestAnimationFrame(() => {
      fitView();
    });
    afterRenderCallback();
  }, [
    afterRenderCallback,
    id,
    fitView,
    graph.links,
    graph.nodes,
    setEdges,
    setNodes,
  ]);

  return (
    <div id={id} style={{ width: "100%", height: "100%" }}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        zoomOnScroll={false}
        zoomOnPinch={false}
        panOnDrag={true}
        preventScrolling={false}
        proOptions={{ hideAttribution: true }}
        nodeOrigin={[0.5, 0.5]}
      >
        {/* <Background /> */}
      </ReactFlow>
    </div>
  );
};
