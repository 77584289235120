import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";

import {
  Account,
  createAccountForUser,
  fetchCurrentUser,
  User,
} from "../../utilities/api";
import { Button } from "../../components/button/Button";
import { Input } from "../../components/forms/forms";
import LoadingGraph from "../../components/loading/loadingGraph";

const DashboardPageContainer = styled.div`
  max-width: 70em;
  margin: 1em auto;
`;

const Container = styled.div`
  font-family: "Roboto Mono", monospace;
  display: flex;
  flex-direction: column;
  justify-content: center;

  max-width: 30em;
  textalign: center;

  padding: 1em;
  margin: auto;

  button {
    margin: 2em auto;
  }
`;

interface CreateAccountPageProps {
  children?: React.ReactNode;
}

const CreateAccountPage: React.FC<CreateAccountPageProps> = () => {
  const [organization, setOrganization] = useState<string | undefined>();

  const navigate = useNavigate();

  const { data: user, isLoading } = useQuery<User, Error>(
    "user",
    fetchCurrentUser
  );

  const queryClient = useQueryClient();

  const mutation = useMutation(createAccountForUser, {
    onSuccess: (account: Account) => {
      queryClient.setQueryData(["accounts"], account);
      navigate(`/accounts/${account.id}`);
    },
  });

  if (isLoading) {
    return <LoadingGraph text="Loading"></LoadingGraph>;
  }

  if ((user?.accounts.length || 0) > 0) {
    navigate(`/accounts/${user?.accounts[0].id}`, { replace: true });
    return <></>;
  }

  const handleSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!organization) {
      return;
    }

    mutation.mutate({ name: organization });
  };

  return (
    <DashboardPageContainer>
      <form onSubmit={handleSubmit}>
        <Container>
          <h2
            style={{
              textAlign: "center",
              margin: "2em 0",
            }}
          >
            Create an organization
          </h2>
          <Input
            type="text"
            id="orgnizationInput"
            label="Name:"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setOrganization(e.target.value)
            }
          />
          <Button label="Create" size="medium" primary />
        </Container>
      </form>
    </DashboardPageContainer>
  );
};
export default CreateAccountPage;
