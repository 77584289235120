import React, { useContext, useEffect, useRef, useState } from "react";

// import useIntersectionObserver from '../../utilities/intersection';

import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { xonokai } from "react-syntax-highlighter/dist/esm/styles/prism";

import { Result, ResultGroup } from "../../utilities/api/results";
import Card from "./resultCard";
import { GraphData, graphFromNodeJSON } from "../../utilities/api";

import { GraphCacheContext } from "../../utilities/graphCache";
import LoadingGraph from "../loading/loadingGraph";
import { LinkButton } from "../button/Button";

import Graph from "graphology";
import { Subgraph } from "../graph/subgraph";
import { ReactFlowProvider } from "reactflow";

// import LoadingGraph from '../loading/loadingGraph';

interface ResultSectionProps {
  section: string;
  results: Result[];
  resultGroup: ResultGroup;
  query?: string;
  subgraph?: GraphData;
}

const SectionHeader = styled.h3`
  margin: 3em 0 0 0;
  overflow-wrap: break-word;
`;

const ResultSection: React.FC<ResultSectionProps> = ({
  resultGroup,
  results,
  section,
  subgraph,
  query,
}) => {
  const { addGraph, getGraph } = useContext(GraphCacheContext);
  const [overviewRendered, setOverviewRendered] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [graph, setGraph] = useState<Graph | undefined>(undefined);
  const [hasLayout, setHasLayout] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);

  let key = `${resultGroup.run_id}-${resultGroup.entity}`;

  useEffect(() => {
    let graphCacheRecord = getGraph(key);

    if (graphCacheRecord === undefined) {
      if (subgraph === undefined) {
        return;
      }

      let loadedGraph = graphFromNodeJSON(resultGroup.entity_id, subgraph);
      setGraph(loadedGraph);
      addGraph(key, loadedGraph, false);
    } else {
      setGraph(graphCacheRecord.graph);
      setHasLayout(
        graphCacheRecord.hasLayout ? graphCacheRecord.hasLayout : false
      );
    }
  }, [
    graph,
    hasLayout,
    setGraph,
    addGraph,
    setHasLayout,
    getGraph,
    key,
    results,
    subgraph,
    resultGroup,
  ]);

  return (
    <div>
      <SectionHeader>{section}</SectionHeader>
      <div style={{ display: "flex", gap: "1em", margin: "0.5em 0" }}>
        <LinkButton
          size="tiny"
          label={`${showDetails ? "Hide" : ""} Details`}
          onClick={() => setShowDetails(!showDetails)}
        />
      </div>
      {showDetails ? (
        <div style={{ margin: "0 1em" }}>
          {graph && graph.size > 1 ? (
            <div>
              <h4>Subgraph</h4>

              <div ref={ref} style={{ height: "400px", width: "100%" }}>
                {overviewRendered === false ? (
                  <LoadingGraph text="Loading ..." />
                ) : undefined}

                {subgraph ? (
                  <ReactFlowProvider>
                    <Subgraph
                      id={key.split("-")[1]}
                      graph={subgraph}
                      afterRenderCallback={() => setOverviewRendered(true)}
                    />
                  </ReactFlowProvider>
                ) : undefined}
              </div>
            </div>
          ) : undefined}
          <h4>Query</h4>
          <ReactMarkdown
            components={{
              code({ node, inline, className, children, style, ...props }) {
                const match = /language-(\w+)/.exec(className || "");
                return !inline && match ? (
                  <SyntaxHighlighter
                    children={String(children).replace(/\n$/, "")}
                    style={xonokai}
                    language={match[1]}
                    PreTag="pre"
                    showLineNumbers={true}
                    {...props}
                  />
                ) : (
                  <code className={className} {...props}>
                    {children}
                  </code>
                );
              },
            }}
          >
            {"~~~sql\n" + query + "\n~~~"}
          </ReactMarkdown>
        </div>
      ) : undefined}

      <br />
      {results.map((result, index) => {
        return <Card key={result.id} result={result} isFirst={index === 0} />;
      })}
    </div>
  );
};

export default ResultSection;
