import React from "react";
import styled from "styled-components";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";

import { Button } from "../button/Button";
import { fetchCurrentUser, User } from "../../utilities/api";
import { Logo } from "./logo";
import { colors } from "../../utilities/style";

interface HeaderProps {
  user?: User;
  onLogin: () => void;
  onLogout: () => void;
  onCreateAccount: () => void;
}

const HeaderWrapper = styled.div`
  font-family: "Roboto Mono", monospace;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 15px 20px;

  div.innerWrapper {
    max-width: 70em;
    margin: 0em auto;

    display: flex;
    align-items: center;
    justify-content: space-between;

    svg {
      display: inline-block;
      vertical-align: top;
    }

    h1 {
      font-weight: 900;
      font-size: 1.5em;
      line-height: 1;
      margin: 6px 0 6px 10px;
      display: inline-block;
      vertical-align: top;
      color: ${colors.primary};
    }

    a + a {
      margin-left: 10px;
    }

    .welcome {
      color: #333;
      font-size: 14px;
      margin-right: 10px;
    }
  }
`;

export const HeaderContainer: React.FC = () => {
  const { isSuccess, data } = useQuery<User, Error>("user", fetchCurrentUser);

  return (
    <Header
      user={isSuccess ? data : undefined}
      onLogin={() => {}}
      onLogout={() => {}}
      onCreateAccount={() => {}}
    />
  );
};

export const Header: React.FC<HeaderProps> = ({
  user,
  onLogin,
  onLogout,
  onCreateAccount,
}) => (
  <header>
    <HeaderWrapper>
      <div className="innerWrapper">
        <div>
          {/* <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g fill="none" fillRule="evenodd">
            <path
              d="M10 0h12a10 10 0 0110 10v12a10 10 0 01-10 10H10A10 10 0 010 22V10A10 10 0 0110 0z"
              fill="#FFF"
            />
            <path
              d="M5.3 10.6l10.4 6v11.1l-10.4-6v-11zm11.4-6.2l9.7 5.5-9.7 5.6V4.4z"
              fill="#555AB9"
            />
            <path
              d="M27.2 10.6v11.2l-10.5 6V16.5l10.5-6zM15.7 4.4v11L6 10l9.7-5.5z"
              fill="#91BAF8"
            />
          </g>
        </svg> */}
          <a href="/">
            <Logo />
            <h1>Whetstone</h1>
          </a>
        </div>
        <div>
          {user ? (
            <>
              <span className="welcome mobileHidden">
                <b>{user.email}</b>
              </span>
              <Link to="accounts">
                <Button size="small" label="Dashboard" primary />
              </Link>
              <Link to="logout" className="mobileHidden">
                <Button size="small" label="Log out" />
              </Link>
            </>
          ) : (
            <>
              <Link to="login">
                <Button size="small" label="Log in" />
              </Link>
              {/* <Link to="register">
              <Button primary size="small" label="Sign up" />
            </Link> */}
            </>
          )}
        </div>
      </div>
    </HeaderWrapper>
  </header>
);
