import { colors } from "../../utilities/style";

interface LogoProps {
  color?: "primary" | "white";
}

export const Logo: React.FC<LogoProps> = ({ color }) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="2.5em"
      height="2.5em"
      viewBox="0 0 2048.0 2048.0"
    >
      <g id="document" transform="matrix(1,0,0,1,1024.0,1024.0)">
        <path
          d="M352.198,272.526 L351.931,-154.345 C351.931,-160.984 345.57,-165.963 338.754,-165.963 C331.483,-165.963 326.03,-160.984 326.03,-154.345 L326.307,288.636 L243.169,340.366 L242.876,-85.4707 C242.876,-91.6943 236.968,-97.0881 230.152,-97.0881 C222.882,-97.0881 217.429,-91.6943 217.429,-85.4707 L217.734,356.192 L133.238,408.767 L132.911,-15.3515 C132.911,-21.5751 127.459,-26.9689 120.188,-26.9689 C112.918,-26.9689 107.465,-21.5751 107.465,-15.3515 L107.804,424.593 L24.2252,476.598 L23.8558,53.5229 C23.8558,46.8844 18.403,41.9056 11.1326,41.9056 C4.31668,41.9056 -1.5905,46.8844 -1.5905,53.5229 C-1.5905,53.5229 -1.33885,341.717 -1.2101,489.164 C-6.79279,489.616 -12.3755,487.981 -17.9582,484.259 L-389.683,251.931 C-396.321,246.952 -401.3,240.314 -401.3,230.357 L-401.3,147.207 L-401.3,-231.38 C-401.3,-241.337 -394.662,-249.634 -388.024,-252.953 L-14.6392,-485.281 C-4.68227,-491.919 5.27466,-491.919 15.2316,-485.281 L386.957,-252.953 C393.595,-247.975 398.573,-241.337 398.573,-231.38 L398.573,147.207 L398.573,230.357 C398.573,240.314 391.935,248.612 385.297,251.931 L352.198,272.526 L352.198,272.526 Z M-666.818,-148.229 L-666.818,81.0034 L-666.818,346.521 L-666.818,386.349 C-666.818,396.306 -660.18,404.603 -653.542,407.922 L-16.2987,806.199 C-4.68227,811.178 6.93414,811.178 13.5721,806.199 L650.815,407.922 C659.113,401.284 664.091,394.647 664.091,386.349 L664.091,346.521 L664.091,81.0034 L664.091,-148.229 L664.091,-347.544 L664.091,-387.372 C664.091,-397.328 657.453,-405.626 650.815,-408.945 L13.5721,-807.222 C1.95568,-812.2 -9.66073,-812.2 -16.2987,-807.222 L-653.542,-408.945 C-661.839,-402.307 -666.818,-395.669 -666.818,-387.372 L-666.818,-347.544 L-666.818,-148.229 Z "
          fill={!!!color || color === "primary" ? colors.primary : colors.white}
          //   fill-opacity="1.00"
        />
      </g>
    </svg>
  );
};
