import styled, { keyframes } from 'styled-components';
import { colors } from '../../utilities/style';

const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;

  margin: 1em;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const blink = keyframes`
    50% {
    opacity: 0.0;
  }
`;

const LoadingText = styled.span<{ delay?: number }>`
  &::after {
    position: relative;
    content: '';
    margin: 0 0 0 0.2em;
    top: 0.05em;
    width: 0.5em;
    height: 1em;

    background: ${colors.primary};
    display: inline-block;

    animation: ${blink} 1.25s step-start 0s infinite;
    animation-delay: ${(props) => (props.delay ? props.delay : 0)}ms;
  }
`;

interface LoadingGraphProps {
  text: string;
}

const LoadingGraph: React.FC<LoadingGraphProps> = ({ text }) => {
  //   let delay = Math.random() * 200;
  return (
    <LoadingContainer>
      <LoadingText delay={undefined}>{text}</LoadingText>
    </LoadingContainer>
  );
};

export default LoadingGraph;
