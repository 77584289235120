import { APIResponse } from "./index";
import { ErrorTypes, baseURL, getToken, GraphData } from "../api";
import { colors } from "../style";

export enum Severity {
  low = "low",
  medium = "medium",
  high = "high",
}

export enum EntityType {
  model = "model",
}

export interface ResultFilters {
  severity?: Severity[];
  ruleSet?: string[];
  entityId?: string[];
  select?: string[];
}

export const severityLevels = {
  [Severity.low]: 1,
  [Severity.medium]: 2,
  [Severity.high]: 3,
};

export const severityColors = {
  [Severity.low]: colors.black,
  [Severity.medium]: colors.quaternary,
  [Severity.high]: colors.primary,
};

export interface Result {
  id: number;
  run_id: number;
  rule_set: string;
  summary: string;
  content: {
    name: string;
    query?: string;
    subgraph?: GraphData;
  };
  severity: Severity;
  score?: number;
  model: string;
}

export interface ResultGroup {
  id: number;

  account_id: number;
  run_id: number;

  entity: string;
  entity_id: string;
  entity_type: EntityType;

  result_count: number;
  results: Result[];
  severity_score: number;

  content?: {
    query?: string;
    subgraph?: GraphData;
  };
}

class UnauthorizedError extends Error {
  constructor(message?: string) {
    super(message); // 'Error' breaks prototype chain here
    this.name = ErrorTypes.UNAURTHORIZED;
    Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
  }
}

export function fetchResultsForRun({
  accountId,
  runId,
  page,
  filters,
}: {
  accountId: number;
  runId: number;
  page: number;
  filters?: ResultFilters;
}): Promise<APIResponse<ResultGroup[]>> {
  let limit = 20;
  let offset = limit * (page - 1);

  let url =
    baseURL +
    `/accounts/${accountId}/runs/${runId}/results?limit=${limit}&offset=${offset}`;

  if (!!filters) {
    if (!!filters.severity) {
      for (let index = 0; index < filters.severity.length; index++) {
        url += `&severity=${filters.severity[index]}`;
      }
    }

    if (!!filters.ruleSet) {
      for (let index = 0; index < filters.ruleSet.length; index++) {
        url += `&rule_set=${filters.ruleSet[index]}`;
      }
    }

    if (!!filters.entityId) {
      for (let index = 0; index < filters.entityId.length; index++) {
        if (filters.entityId[index] !== "") {
          url += `&entity_id=${filters.entityId[index]}`;
        }
      }
    }
    if (!!filters.select) {
      for (let index = 0; index < filters.select.length; index++) {
        if (filters.select[index] !== "") {
          url += `&select=${encodeURIComponent(filters.select[index])}`;
        }
      }
    }
  }

  return fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + getToken().access_token,
    },
  }).then((response) => {
    if (response.status === 200) {
      return response.json();
    } else if (response.status === 401) {
      throw new UnauthorizedError(
        "Your user is not authorized to access this resource."
      );
    }
    throw new Error(response.status.toString());
  });
}

export function fetchDemoResultsForDemoRun({
  runId,
  page,
  filters,
}: {
  runId: number;
  page: number;
  filters?: ResultFilters;
}): Promise<APIResponse<ResultGroup[]>> {
  let limit = 20;
  let offset = limit * (page - 1);

  let url =
    baseURL + `/demo/runs/${runId}/results?limit=${limit}&offset=${offset}`;

  if (!!filters) {
    if (!!filters.severity) {
      for (let index = 0; index < filters.severity.length; index++) {
        url += `&severity=${filters.severity[index]}`;
      }
    }

    if (!!filters.ruleSet) {
      for (let index = 0; index < filters.ruleSet.length; index++) {
        url += `&rule_set=${filters.ruleSet[index]}`;
      }
    }
    if (!!filters.entityId) {
      for (let index = 0; index < filters.entityId.length; index++) {
        if (filters.entityId[index] !== "") {
          url += `&entity_id=${filters.entityId[index]}`;
        }
      }
    }
  }

  return fetch(url, {
    method: "GET",
  }).then((response) => {
    if (response.status === 200) {
      return response.json();
    } else if (response.status === 401) {
      throw new UnauthorizedError(
        "Your user is not authorized to access this resource."
      );
    }
    throw new Error(response.status.toString());
  });
}
