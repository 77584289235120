import React from "react";
import { useNavigate, Link } from "react-router-dom";
import { DateTime, Duration } from "luxon";
import styled from "styled-components";

import { Run } from "../../utilities/api/runs";
import { Project } from "../../utilities/api/projects";
import { colors } from "../../utilities/style";
import {
  Point,
  Sparkline,
  SparklineContainer,
  SparklineLabel,
  SparklinePoints,
} from "../sparkline/sparkline";

interface RunListProps {
  runs: Run[];
  isDemo?: boolean;
}

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableRow = styled.tr`
  width: 100%;
  cursor: pointer;

  :hover {
    background-color: #f5f5f5;
  }
`;

const TableHead = styled(TableRow)`
  cursor: default;
  :hover {
    background-color: transparent;
  }
`;

const TableHeadCell = styled.th`
  padding: 0.5em 0.5em;
  text-align: left;
  font-weight: 100;
  border-bottom: 1px solid black;
`;

const TableCell = styled.td`
  padding: 0 0.5em;
  box-sizing: border-box;
  border-bottom: 1px solid #f0f0f0;
`;

interface RunStatusCellProps {
  status: "queued" | "starting" | "running" | "success" | "error" | "cancelled";
}

const RunStatusCell = styled(TableCell)<RunStatusCellProps>`
  // text-align: center;
  text-transform: capitalize;

  max-width: 2em;
  height: 5em;

  color: ${(props) =>
    props.status === "success"
      ? colors.secondary
      : props.status === "queued"
      ? colors.black
      : props.status === "starting" || props.status === "running"
      ? colors.tertiary
      : colors.primary};
`;

const RunTimingCell = styled(TableCell)`
  font-weight: 200;
  font-size: 0.9em;

  max-width: 2em;

  p {
    margin: 0.25em 0;
  }
`;

const RunInfoCell = styled(TableCell)`
  font-weight: 200;
  font-size: 0.9em;
`;

const TableBody = styled.tbody``;

export const RunList: React.FC<RunListProps> = (props) => {
  const navigate = useNavigate();

  return (
    <Table>
      <thead>
        <TableHead>
          <TableHeadCell>Analysis</TableHeadCell>
          <TableHeadCell>
            <abbr title="The dbt-generated ID for the operation that generated the artifacts analyzed in this Whetstone run.">
              Invocation
            </abbr>
          </TableHeadCell>
          <TableHeadCell style={{ width: "10em" }}>Status</TableHeadCell>
          <TableHeadCell className="mobileHidden" style={{ width: "15em" }}>
            Timing
          </TableHeadCell>
        </TableHead>
      </thead>
      <TableBody>
        {props.runs.map((run) => {
          let queued_at = DateTime.fromISO(run.created_at);
          let duration: Duration | undefined = undefined;
          if (run.duration) {
            duration = Duration.fromMillis(run.duration * 1000).shiftTo(
              "hours",
              "minutes",
              "seconds"
            );
          }

          return (
            <TableRow
              key={run.id}
              onClick={() => {
                if (props.isDemo) {
                  navigate(`/demo/${run.correlation_id}/analyses/${run.id}`);
                } else {
                  navigate(
                    `/accounts/${run.account_id}/projects/${run.project_id}/analyses/${run.id}`
                  );
                }
              }}
            >
              <RunInfoCell>
                #{props.isDemo ? (run.id as string).slice(-12) : run.id}
              </RunInfoCell>
              <RunInfoCell>
                <Link
                  to={
                    props.isDemo
                      ? `/demo/${run.correlation_id}/analyses/${run.id}`
                      : `/accounts/${run.account_id}/projects/${run.project_id}/analyses/${run.id}`
                  }
                >
                  {run.metadata
                    ? run.metadata.invocation_id
                    : "Unknown invocation"}
                </Link>
              </RunInfoCell>
              <RunStatusCell status={run.status}>
                {run.status === "success" ? "Completed" : run.status}
              </RunStatusCell>
              <RunTimingCell className="mobileHidden">
                <p>
                  Created{" "}
                  <abbr title={queued_at.toISO()}>
                    {queued_at.toRelative()}
                  </abbr>{" "}
                </p>
                {duration ? (
                  <p>
                    Took{" "}
                    {duration.hours ? `${duration.hours} Hours` : undefined}{" "}
                    {duration.minutes
                      ? `${duration.minutes} Minutes`
                      : undefined}{" "}
                    {duration.seconds
                      ? `${Math.round(duration.seconds * 10) / 10} Seconds`
                      : undefined}{" "}
                  </p>
                ) : undefined}
              </RunTimingCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

interface RunStatusTextProps {
  status: "queued" | "starting" | "running" | "success" | "error" | "cancelled";
}

const RunStatusText = styled.span<RunStatusTextProps>`
  text-transform: capitalize;

  color: ${(props) =>
    props.status === "success"
      ? colors.secondary
      : props.status === "queued"
      ? colors.black
      : props.status === "starting" || props.status === "running"
      ? colors.tertiary
      : colors.primary};
`;

const ProjectName = styled.h2`
  margin: 0;
`;

const ProjectCardContainer = styled.div`
  // margin: 1em;
  border-bottom: 1px solid #f0f0f0;
  padding: 1em 0;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

interface ProjectCardProps {
  project: Project;
  latestRun?: Run;
}

export const ProjectCard: React.FC<ProjectCardProps> = (props) => {
  const { project, latestRun } = props;

  let statisticsValues = project.statistics
    ? project.statistics.meshedness_coefficient.slice(0).reverse()
    : [];

  let resultsValues = project.statistics
    ? project.statistics.results.slice(0).reverse()
    : [];

  console.log(resultsValues);

  return (
    <ProjectCardContainer>
      <div style={{ flexGrow: 2 }}>
        <Link to={`/accounts/${project.account_id}/projects/${project.id}`}>
          <ProjectName>{project.name}</ProjectName>
        </Link>
        <p style={{ margin: "0" }}>
          <small>
            Latest run:{" "}
            {latestRun
              ? latestRun.started_at
                ? DateTime.fromISO(latestRun.started_at).toLocaleString(
                    DateTime.DATETIME_FULL_WITH_SECONDS
                  )
                : undefined
              : "Never"}
          </small>
        </p>
        {latestRun ? (
          <div>
            <p style={{ margin: "0" }}>
              <small>
                Invocation:{" "}
                <Link
                  to={`/accounts/${latestRun.account_id}/projects/${latestRun.project_id}/analyses/${latestRun.id}`}
                >
                  {latestRun.metadata?.invocation_id}
                </Link>
              </small>
            </p>
            <p style={{ margin: "0" }}>
              <small>
                Status:{" "}
                <RunStatusText status={latestRun?.status}>
                  {latestRun?.status === "success"
                    ? "Complete"
                    : latestRun.status}
                </RunStatusText>
              </small>
            </p>
          </div>
        ) : undefined}
      </div>
      {project.statistics ? (
        <div style={{ flexBasis: "25%" }}>
          <small>Complexity</small>
          <div>
            <SparklineContainer
              width={250}
              height={60}
              data={statisticsValues.map((value, index) => {
                return { x: index, y: value } as Point;
              })}
              max={1}
            >
              <Sparkline color={colors.primary} fill={`${colors.primary}11`} />
              <SparklineLabel type="percentage" />
              <SparklinePoints color={`${colors.primary}aa`} />
            </SparklineContainer>
          </div>
        </div>
      ) : undefined}
      {project.statistics ? (
        <div style={{ flexBasis: "25%" }}>
          <small>Results</small>
          <div>
            <SparklineContainer
              width={250}
              height={60}
              data={resultsValues.map((value, index) => {
                return { x: index, y: value } as Point;
              })}
            >
              <Sparkline color={colors.primary} fill={`${colors.primary}11`} />
              <SparklineLabel type="number" />
              <SparklinePoints color={`${colors.primary}aa`} />
            </SparklineContainer>
          </div>
        </div>
      ) : undefined}
    </ProjectCardContainer>
  );
};
