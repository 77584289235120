import { useState } from "react";
import styled from "styled-components";
import { colors } from "../../utilities/style";

interface CheckboxOptions {
  value: string;
  label: string;
}

interface SelectProps {
  name: string;
  label: string;
  options: CheckboxOptions[];
  onChange?: (filterChange: { [index: string]: string[] }) => {};
  multiple?: boolean;
}

const Checkbox = styled.input`
  appearance: none;

  &::before {
    vertical-align: bottom;
    display: inline-block;
    font-family: "Roboto Mono", monospace;
    content: "[ ]";
  }

  &:checked {
    color: ${colors.primary};

    &::before {
      content: "[X]";
    }
  }
`;

export const MultiCheckbox: React.FC<SelectProps> = ({
  name,
  label,
  options,
  multiple,
  onChange,
}) => {
  const [filters, setFilters] = useState<string[]>([]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    console.log(event);

    let newFilters = filters;
    if (event.target.checked) {
      newFilters.push(event.target.id);
    } else {
      newFilters = newFilters.filter(function (e) {
        return e !== event.target.id;
      });
    }

    console.log(newFilters);

    setFilters(newFilters);

    if (!!onChange) {
      onChange({ [name]: newFilters });
    }
  };

  return (
    <fieldset style={{ display: "flex", flexDirection: "column" }}>
      <legend>{label}</legend>
      {options.map((option) => (
        <label key={`label-${option.value}`} htmlFor={option.value}>
          <Checkbox
            type="checkbox"
            key={option.label}
            id={option.value}
            value={option.value}
            onChange={handleChange}
            checked={filters.indexOf(option.value) >= 0}
          />
          {option.label}
        </label>
      ))}
    </fieldset>
  );
};
