import { baseURL, getToken } from "../api";

export interface Project {
  id: number;
  name: string;
  account_id: number;
  status: string;
  // TODO: Update to have real datetime types
  created_at: string;
  updated_at: string;

  statistics?: {
    [index: string]: number[];
  };
}

export function fetchProjects({
  accountId,
}: {
  accountId: number;
}): Promise<Project[]> {
  return fetch(baseURL + `/accounts/${accountId}/projects`, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + getToken().access_token,
    },
  })
    .then((response) => response.json())
    .then((response) => response.data);
}

export function fetchProjectForAccount({
  accountId,
  projectId,
}: {
  accountId: number;
  projectId: number;
}): Promise<Project> {
  try {
    let token = getToken();

    return fetch(baseURL + `/accounts/${accountId}/projects/${projectId}`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token.access_token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => response.data)
      .catch((error) => {
        console.log(error);
        throw error;
      });
  } catch (error) {
    throw error;
  }
}

export function createProject({
  accountId,
  name,
}: {
  accountId: number;
  name: string;
}): Promise<Project> {
  return fetch(`${baseURL}/accounts/${accountId}/projects`, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + getToken().access_token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ name: name }),
  })
    .then((response) => response.json())
    .then((response) => response.data);
}

interface ProjectUpdate {
  name: string;
  status: "active" | "inactive";
}

export async function updateProject({
  accountId,
  projectId,
  projectUpdate,
}: {
  accountId: number;
  projectId: number;
  projectUpdate: ProjectUpdate;
}): Promise<Project> {
  return fetch(`${baseURL}/accounts/${accountId}/projects/${projectId}`, {
    method: "PUT",
    headers: {
      Authorization: "Bearer " + getToken().access_token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(projectUpdate),
  })
    .then((response) => response.json())
    .then((response) => response.data);
}
