import React, { ReactNode } from "react";
import styled from "styled-components";

import { Button } from "../../components/button/Button";
import { Input } from "../../components/forms/forms";

const RegistrationContainer = styled.div`
  font-family: "Roboto Mono", monospace;
  display: flex;
  flex-direction: column;
  justify-content: center;

  max-width: 30em;
  textalign: center;

  padding: 1em;
  margin: auto;

  button {
    margin: 2em auto;
  }
`;

interface LoginFormProps {
  handleSubmit: (e: React.ChangeEvent<HTMLFormElement>) => void;
  setUserName: (username: string) => void;
  setPassword: (password: string) => void;
  children?: ReactNode;
}

export const RegistrationForm: React.FC<LoginFormProps> = ({
  handleSubmit,
  setUserName,
  setPassword,
  children,
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <RegistrationContainer>
        <h2
          style={{
            textAlign: "center",
            margin: "2em 0",
          }}
        >
          Register for Whetstone
        </h2>
        <Input
          type="email"
          id="usernameInput"
          label="Email:"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setUserName(e.target.value)
          }
        />
        <Input
          type="password"
          id="passwordInput"
          label="Password:"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setPassword(e.target.value)
          }
        />
        <Button label="Register" size="medium" primary />
      </RegistrationContainer>
    </form>
  );
};
