import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { Project, updateProject } from "../../utilities/api/projects";
import { colors } from "../../utilities/style";
import { Button } from "../button/Button";
import { ButtonNav, Dialog } from "../dialog/dialog";

interface DeleteDialogProps {
  project: Project;
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const DeleteDialog: React.FC<DeleteDialogProps> = ({
  project,
  open,
  setOpen,
}) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [confirmationString, setConfirmationString] = useState("");
  const [validationError, setValidationError] =
    useState<string | undefined>(undefined);

  const mutation = useMutation(updateProject, {
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries("projects");
    },
  });

  const reset = () => {
    let element: HTMLInputElement | null = document.getElementById(
      "deleteConfirmation"
    ) as HTMLInputElement;
    if (!!element) {
      element.value = "";
    }

    setConfirmationString("");
    setValidationError(undefined);
  };

  const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    console.log(confirmationString);

    if (confirmationString !== project.name) {
      setValidationError("Provided confirmation value not valid.");
      return;
    }

    mutation.mutate({
      accountId: project.account_id,
      projectId: project.id,
      projectUpdate: {
        name: project.name,
        status: "inactive",
      },
    });

    setOpen(false);
    navigate(`/accounts/${project.account_id}`);
  };

  return (
    <Dialog open={open} setOpen={setOpen}>
      <h2>Delete Project</h2>
      <p>
        To <b>delete</b> your project, please type your project's name ("
        {project.name}") into the confirmation input below.
      </p>
      <form style={{ margin: "2em 1em 0 1em" }} onSubmit={handleSubmit}>
        <label htmlFor="deleteConfirmation">
          Confirmation:{" "}
          <input
            id="deleteConfirmation"
            type="text"
            placeholder="Project Name"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setConfirmationString(e.target.value)
            }
            onSubmitCapture={() => {}}
          />
        </label>
        {validationError ? (
          <div
            style={{
              margin: "1em",
              padding: "0.5em",
              border: `1px dashed ${colors.primary}`,
              color: colors.primary,
            }}
          >
            <small>{validationError}</small>
          </div>
        ) : undefined}
        <ButtonNav>
          <Button
            label="Cancel"
            primary={false}
            onClick={() => {
              reset();
              setOpen(false);
            }}
            type="button"
          />
          <Button label="Delete" primary={true} type="submit" />
        </ButtonNav>
      </form>
    </Dialog>
  );
};
