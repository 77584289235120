import React from 'react';
// import styled from 'styled-components';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useQueryClient } from 'react-query';
import { clearToken, fetchCurrentUser, User } from '../../utilities/api';

const LogoutPage: React.FC = () => {
  const navigate = useNavigate();
  const { data } = useQuery<User, Error>('user', fetchCurrentUser);
  const queryClient = useQueryClient();

  if (data !== undefined) {
    clearToken();
    queryClient.invalidateQueries();
    navigate('/');
  }

  return <></>;
};

export default LogoutPage;
