import React from "react";
import styled from "styled-components";
import { RunStatisticDict } from "../../utilities/api/runs";
import { colors } from "../../utilities/style";

interface RunDetailsProps {
  metrics: RunStatisticDict;
}

interface MetricTextProps {
  change: "none" | "increase" | "decrease";
}

const MetricText = styled.span<MetricTextProps>`
    color: ${(props) =>
      props.change === "increase"
        ? colors.primary
        : props.change === "decrease"
        ? colors.secondary
        : colors.black} }

        border-bottom: 1px solid
        ${(props) =>
          props.change === "increase"
            ? colors.primary
            : props.change === "decrease"
            ? colors.secondary
            : colors.black} }


`;

interface RunTextMetricProps {
  statistic: string;
  label: string;
  value: number;
  delta: number;
  invert?: boolean;
  description?: string;
  type?: "number" | "percentage";
}

export const RunTextMetric: React.FC<RunTextMetricProps> = ({
  label,
  value,
  delta,
  type,
  invert,
  description,
}) => {
  const isInverted = !!invert;
  let change: "none" | "increase" | "decrease" =
    delta > 0 ? "increase" : delta < 0 ? "decrease" : "none";

  if (isInverted) {
    change =
      change === "increase"
        ? "decrease"
        : change === "decrease"
        ? "increase"
        : change;
  }

  const suffix = type === "percentage" ? "%" : "";

  let reportedValue = value;
  let reportedDelta = delta;

  if (reportedValue === undefined) {
    return <></>;
  }

  let precision = 0;
  if (type === "percentage") {
    reportedValue = Math.round(reportedValue * 10000) / 100;
    if (delta !== null && delta !== undefined) {
      reportedDelta = Math.round(reportedDelta * 10000) / 100;
    }
    precision = 1;
  }

  return (
    // <p style={{ margin: "0.5em 0" }}>
    <tr>
      <td>
        <abbr title={description}>{label}</abbr>:{" "}
      </td>
      <td style={{ padding: "0.25em" }}>
        <MetricText change={change}>
          {reportedValue.toFixed(precision)}
          {suffix}
        </MetricText>{" "}
        {delta === null || reportedDelta === 0 ? undefined : (
          <span>
            ({reportedDelta > 0 ? "+" : ""}
            {reportedDelta})
          </span>
        )}
      </td>
    </tr>
    // </p>
  );
};

export const RunDetails: React.FC<RunDetailsProps> = ({ metrics }) => {
  return (
    <table>
      <tbody>
        <RunTextMetric
          {...metrics.results}
          description="The total number of results returned by the Whetstone Engine."
        />
        <RunTextMetric
          {...metrics.meshedness_coefficient}
          type="percentage"
          description="The complexity of the graph, where 0% is a tree or forest, and 100% is a fully-connected graph. Smaller is better."
        />
        <RunTextMetric
          {...metrics.test_coverage}
          type="percentage"
          invert
          description="The percentage of models in the project with primary key tests."
        />
      </tbody>
    </table>
  );
};
