import React, { useState } from "react";
import styled from "styled-components";
import { useParams, useNavigate } from "react-router-dom";
import { useQueries, useQuery } from "react-query";

import { fetchProjects, Project } from "../../utilities/api/projects";
import {
  Account,
  fetchAccountbyId,
  fetchCurrentUser,
  User,
} from "../../utilities/api";
import { ProjectCard } from "../../components/project/projectcard";
import { Button } from "../../components/button/Button";
import { ProjectCreateDialog } from "../../components/project/projectCreateDialog";
import { Breadcrumbs } from "../../components/header/breadcrumbs";
import { fetchLatestRunForProject } from "../../utilities/api/runs";
// import { ResultsContext } from "../../utilities/engine";

const DashboardPageContainer = styled.div`
  max-width: 70em;
  margin: 1em auto;
`;

const DashboardPage: React.FC = () => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const navigate = useNavigate();
  const { accountId } = useParams();

  const { data: user } = useQuery<User, Error>("user", fetchCurrentUser);

  const queryAccountId = accountId
    ? accountId
    : (user?.accounts.length || 0) > 0
    ? user?.accounts[0].id
    : undefined;

  const { isSuccess: isAccountSuccess, data: account } = useQuery<
    Account,
    Error
  >(
    ["accounts", { accountId }],
    ({ queryKey }: any) =>
      fetchAccountbyId({ accountId: queryKey[1].accountId }),
    {
      enabled: !!accountId,
    }
  );

  const {
    isSuccess: isProjectSuccess,
    data: projects,
    isError: isProjectError,
    error: projectError,
  } = useQuery<Project[], Error>(
    ["projects", { accountId: queryAccountId }],
    ({ queryKey }) => fetchProjects(queryKey[1] as { accountId: number }),
    {
      // The query will not execute until the userId exists

      enabled: !!queryAccountId,
    }
  );

  const latestRunQueries = useQueries(
    (projects || []).map((project) => {
      return {
        queryKey: ["latestRun", { projectId: project.id, accountId }],

        queryFn: ({ queryKey }: any) =>
          fetchLatestRunForProject({
            accountId: queryKey[1].accountId,
            projectId: queryKey[1].projectId,
          }),
        enabled: !!accountId && !!project,
      };
    })
  );

  // If we're not authenticated, then navigate to "/login"
  if (
    isProjectError &&
    projectError &&
    !!projectError.name &&
    projectError.name === "MissingTokenError"
  ) {
    navigate("/login");
  }

  if (!accountId && (!!user?.accounts.length || 0) > 0) {
    navigate(`/accounts/${user?.accounts[0].id}`);
  }

  if (!accountId && (!!user?.accounts.length || 0) === 0) {
    navigate(`/new_account`);
    return <></>;
  }

  return (
    <DashboardPageContainer>
      {account && isAccountSuccess ? (
        <Breadcrumbs
          links={[{ name: `${account.name}`, url: `/accounts/${accountId}` }]}
        />
      ) : (
        <Breadcrumbs links={[{ name: " ", url: `/accounts/${accountId}` }]} />
      )}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h1>Projects</h1>{" "}
        <ProjectCreateDialog
          accountId={queryAccountId as number}
          open={dialogOpen}
          setOpen={(open: boolean) => setDialogOpen(open)}
        />
        <Button
          size="tiny"
          label="Create Project"
          onClick={() => {
            setDialogOpen(true);
          }}
        />
      </div>
      {isProjectSuccess && projects ? (
        <div>
          {projects.map((project, index) => {
            if (project.status !== "active") {
              return <></>;
            }

            let runQuery = latestRunQueries[index];

            return (
              <ProjectCard
                project={project}
                key={project.id}
                latestRun={
                  runQuery.isSuccess ? runQuery.data.data[0] : undefined
                }
              />
            );
          })}
        </div>
      ) : undefined}
    </DashboardPageContainer>
  );
};
export default DashboardPage;
