import { ScaleLinear } from "d3";
import { max } from "d3-array";
import { scaleLinear } from "d3-scale";
import { area, line } from "d3-shape";
import React, { ReactElement } from "react";
import { colors } from "../../utilities/style";

interface Margin {
  top: number;
  right: number;
  bottom: number;
  left: number;
}

interface SparklineContainerProps {
  width: number;
  height: number;
  data: Point[];
  margin?: Margin;
  max?: number;
}

const defaultMargin = { top: 20, right: 5, bottom: 1, left: 3 };

export const SparklineContainer: React.FC<
  React.PropsWithChildren<SparklineContainerProps>
> = ({ width, height, children, margin, data, max }) => {
  return (
    <svg viewBox={`0 0 ${width} ${height}`}>
      {React.Children.map(children, (child: React.ReactNode) =>
        React.cloneElement(child as ReactElement, {
          width,
          height,
          margin: margin || defaultMargin,
          data,
          max,
        })
      )}
    </svg>
  );
};

export interface Point {
  x: number;
  y?: number;
}

interface SparklineProps {
  data?: Point[];
  width?: number;
  height?: number;
  color?: string;
  fill?: string;
  margin?: Margin;
  max?: number;
}

export const Sparkline: React.FC<SparklineProps> = ({
  data,
  width,
  height,
  color,
  fill,
  margin,
  max: maxValue,
}) => {
  if (!!!data) {
    return <></>;
  }

  const pathMargin = margin || defaultMargin;

  let x: ScaleLinear<number, number, never> = scaleLinear()
    .domain([0, 15])
    .range([pathMargin.left, (width || 280) - pathMargin.right]);

  let y: ScaleLinear<number, number, never> = scaleLinear()
    .domain([0, maxValue || max(data, (d) => d.y)] as [number, number])
    .range([(height || 40) - pathMargin.bottom, pathMargin.top]);

  let linePath = line<Point>()
    .x(({ x: xValue }: Point) => {
      return x(xValue);
    })
    .y(({ y: yValue }: Point) => y(yValue || 0));

  let areaGenerator = area<Point>()
    .x(({ x: xValue }: Point) => {
      return x(xValue);
    })
    .y1(({ y: yValue }: Point) => y(yValue || 0))
    .y0(height || 40);

  return (
    <g>
      <path
        d={`${linePath(data)}`}
        fill="none"
        stroke={color || colors.gray}
        stroke-width="1"
        stroke-miterlimit="1"
      />
      <path d={`${areaGenerator(data)}`} fill={fill || colors.gray} />
    </g>
  );
};

interface SparklineLabelProps {
  data?: Point[];
  margin?: Margin;
  width?: number;
  height?: number;
  type: "number" | "percentage";
  max?: number;
}

export const SparklineLabel: React.FC<SparklineLabelProps> = ({
  data,
  margin,
  width,
  height,
  type,
  max: maxValue,
}) => {
  const LabelMargin = margin || defaultMargin;

  if (!!!data) {
    return <></>;
  }

  let xScale: ScaleLinear<number, number, never> = scaleLinear()
    .domain([0, 15])
    // .domain(extent(data, (d) => d.x) as [number, number])
    .range([LabelMargin.left, (width || 280) - LabelMargin.right]);

  let yScale: ScaleLinear<number, number, never> = scaleLinear()
    .domain([0, maxValue || max(data, (d) => d.y)] as [number, number])
    .range([(height || 40) - LabelMargin.bottom, LabelMargin.top]);

  const lastValue = data.slice(-1)[0];

  if (!!lastValue) {
    return (
      <text
        x={xScale(lastValue.x)}
        y={yScale(lastValue.y || 0)}
        dy={-3}
        text-anchor={
          xScale(lastValue.x) / (width || 280) <= 2 / 15
            ? "begining"
            : xScale(lastValue.x) / (width || 280) < 13 / 15
            ? "middle"
            : "end"
        }
        style={{ fontSize: "0.75em", fontFamily: "roboto mono" }}
      >
        {!!lastValue.y
          ? type === "percentage"
            ? `${Math.floor(lastValue.y * 100)}%`
            : lastValue.y
          : undefined}
      </text>
    );
  }

  return <></>;
};

interface SparklinePointsProps {
  data?: Point[];
  color?: string;
  margin?: Margin;
  width?: number;
  height?: number;
  max?: number;
}

export const SparklinePoints: React.FC<SparklinePointsProps> = ({
  data,
  color,
  margin,
  width,
  height,
  max: maxValue,
}) => {
  if (!!!data) {
    return <></>;
  }

  const LabelMargin = margin || defaultMargin;
  const PointColor = color || colors.primary;

  let xScale: ScaleLinear<number, number, never> = scaleLinear()
    .domain([0, 15])
    // .domain(extent(data, (d) => d.x) as [number, number])
    .range([LabelMargin.left, (width || 280) - LabelMargin.right]);

  let yScale: ScaleLinear<number, number, never> = scaleLinear()
    .domain([0, maxValue || max(data, (d) => d.y)] as [number, number])
    .range([(height || 40) - LabelMargin.bottom, LabelMargin.top]);

  return (
    <g>
      {data.map((point: Point) => (
        <circle
          cx={xScale(point.x)}
          cy={yScale(point.y || 0)}
          r={(height || 40) * 0.02}
          stroke={PointColor}
          fill={PointColor}
        />
      ))}
    </g>
  );
};
