import React from "react";
import { Outlet } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import "./App.css";
// import { FileUploader } from './components/fileUploader';

// Providers
import { MetadataProvider } from "./utilities/metadata";
import { HeaderContainer } from "./components/header/Header";
import { GraphCacheProvider } from "./utilities/graphCache";

const whetstoneClient = new QueryClient();

export const App: React.FC = () => {
  return (
    <div className="App">
      <QueryClientProvider client={whetstoneClient}>
        <HeaderContainer />
        <MetadataProvider>
          <GraphCacheProvider>
            <Outlet />
          </GraphCacheProvider>
        </MetadataProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </div>
  );
};
