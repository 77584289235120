import React, { ReactNode } from "react";

import { LoginForm } from "../../components/auth/login";
import { AccessToken } from "../../utilities/api";

interface LoginProps {
  setToken: (accessToken: AccessToken) => void;
  getToken: () => AccessToken;
  children?: ReactNode;
}

const Login: React.FC<LoginProps> = ({ setToken, getToken, children }) => {
  if (getToken !== undefined) {
  }

  return (
    <div>
      <LoginForm setToken={setToken} />
    </div>
  );
};
export default Login;
