import React from "react";

import { Result, ResultGroup } from "../../utilities/api/results";
import StyledPaginator from "./pagination";
import ResultSection from "./resultSection";

interface ResultListProps {
  resultGroups: ResultGroup[];
  page: number;
  totalPages: number;
}

const severityScores = {
  low: 1,
  medium: 10,
  high: 100,
};

const ResultList: React.FC<ResultListProps> = ({
  resultGroups,
  page,
  totalPages,
}) => {
  if (resultGroups.length === 0) {
    return (
      <div style={{ margin: "5em auto" }}>
        <h4>There are no results for this selection.</h4>
      </div>
    );
  }

  resultGroups.forEach((resultGroup) => {
    resultGroup.results = resultGroup.results
      .map((result): Result => {
        result.score = severityScores[result.severity];
        return result;
      })
      .sort((a: Result, b: Result) => {
        return (b.score || 0) - (a.score || 0);
      });
  });

  return (
    <div style={{ margin: "0.5em 0", width: "100%" }}>
      <div>
        {resultGroups.map((resultGroup, index) => (
          <ResultSection
            key={`resultSection${index}`}
            resultGroup={resultGroup}
            query={resultGroup.content?.query}
            subgraph={resultGroup.content?.subgraph}
            results={resultGroup.results}
            section={resultGroup.entity}
          />
        ))}
      </div>
      <StyledPaginator page={page} totalPages={totalPages} />
    </div>
  );
};

export default ResultList;
