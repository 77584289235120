import styled from "styled-components";
import { colors } from "../../utilities/style";
import { Logo } from "../header/logo";

const StyledFooter = styled.footer`
  background-color: ${colors.primary};
  color: ${colors.white};
`;

const FooterContent = styled.div`
  max-width: 70em;
  margin: 0 auto;
  padding: 2em 0;

  display: flex;
  justify-content: space-between;
  align-items: center;

  a {
    color: ${colors.white};
  }
`;

const LogoContainer = styled.div`
  h1 {
    display: inline-block;
    font-weight: 900;
    font-size: 1.5em;
    line-height: 1;
    margin: 6px 0 6px 10px;
    vertical-align: top;
  }
`;

export const Footer: React.FC = () => {
  return (
    <StyledFooter>
      <FooterContent>
        <LogoContainer>
          <Logo color="white" />
          <h1>Whetstone</h1>
          <p>
            <small>
              Made with ❤️ by{" "}
              <a
                href="https://nicholasyager.com"
                target="_blank"
                rel="noreferrer"
              >
                Nicholas Yager
              </a>
            </small>
          </p>
        </LogoContainer>
        <div style={{ margin: "0 3em" }}>
          <p>
            <a
              href="https://gitlab.com/nicholasyager/whetstone"
              target="_blank"
              rel="noreferrer"
            >
              View on GitLab
            </a>
          </p>

          <p>
            <a
              href="mailto:yager@nicholasyager.com"
              target="_blank"
              rel="noreferrer"
            >
              Email
            </a>
          </p>
        </div>
      </FooterContent>
    </StyledFooter>
  );
};
