import React from "react";
import styled from "styled-components";

const DialogWrapper = styled.dialog`
  font-family: "Roboto Mono", monospace;
  background-color: var(--white-color);

  position: fixed;

  width: 100%;
  max-width: 30em;
  padding: 2em;

  top: 50%;

  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
`;

export interface DialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  id?: string;
  onSubmit?: (e: React.ChangeEvent<HTMLFormElement>) => {};
  onClose?: (e: React.ChangeEvent<HTMLFormElement>) => {};
}

export const Dialog: React.FC<DialogProps> = ({ open, id, children }) => {
  return (
    <DialogWrapper id={id} open={open}>
      <div>{children}</div>
    </DialogWrapper>
  );
};

export const ButtonNav = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 2em 0 0 0;
`;
