import React, { useState } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { useQuery, useQueryClient, useMutation } from "react-query";

import { Run, fetchDemoRun, retryDemoRun } from "../../../utilities/api/runs";
import {
  fetchDemoResultsForDemoRun,
  ResultGroup,
} from "../../../utilities/api/results";
import { APIResponse } from "../../../utilities/api/index";

import ResultsPage from "../../../components/results/resultsPage";
import Graph from "graphology";
import { fetchGraph } from "../../../utilities/api";

const DemoAnalysisPage: React.FC = () => {
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const mutateRuns = useMutation(retryDemoRun, {
    onSuccess: (data) => {
      // Invalidate and refetch
      queryClient.invalidateQueries("demoRuns");
      navigate(`/demo/${data.correlation_id}/analyses/${data.id}`);
    },
  });

  const { correlationId, demoRunId: runId } = useParams();

  const runQuery = useQuery<Run, Error>(
    ["demoRuns", { runId }],
    ({ queryKey }: any) =>
      fetchDemoRun({
        runId: queryKey[1].runId,
      }),
    {
      // The query will not execute until the userId exists
      enabled: !!runId,
      refetchOnWindowFocus: false,
      refetchInterval: (data) => {
        if (data === undefined) {
          return 5000;
        }
        if (data.status === "success" || data.status === "error") {
          return false;
        }
        return 5000;
      },
    }
  );

  let [searchParams] = useSearchParams();
  let pageValue = searchParams.get("page") || "1";

  let page = parseInt(pageValue);

  const [filters, setFilters] = useState({});

  const onFilterChange = (filterChange: { [index: string]: string[] }) => {
    console.log(filterChange);

    let newFilters: { [index: string]: string[] } = {
      ...filters,
      ...filterChange,
    };

    Object.keys(newFilters).forEach((key) =>
      newFilters[key].length === 0 ? delete newFilters[key] : {}
    );

    setFilters(newFilters);
  };

  const resultQuery = useQuery<APIResponse<ResultGroup[]>, Error>(
    ["demoRunResults", { runId, page, filters }],
    ({ queryKey }: any) =>
      fetchDemoResultsForDemoRun({
        runId: queryKey[1].runId,
        page: queryKey[1].page,
        filters: queryKey[1].filters,
      }),
    {
      // The query will not execute until the userId exists

      enabled: !!runId && runQuery.data?.status === "success",
      keepPreviousData: true,
    }
  );

  const graphQuery = useQuery<Graph, Error>(
    ["graphs", { graphUrl: runQuery.data?.graph_url }],
    ({ queryKey }: any) =>
      fetchGraph({
        graphUrl: queryKey[1].graphUrl,
        resultGroups: resultQuery.data?.data,
      }),
    {
      // The query will not execute until the userId exists

      enabled:
        !!runQuery.isSuccess &&
        runQuery.data.status === "success" &&
        !!resultQuery.isSuccess &&
        !!resultQuery.data,
      refetchOnWindowFocus: false,
    }
  );

  if (!runId) {
    console.error("Run ID not known!");
    return <></>;
  }

  return (
    <ResultsPage
      breadcrumbs={[
        {
          name: `Demo #${correlationId?.slice(-12)}`,
          url: `/demo/${correlationId}`,
        },
      ]}
      runId={runId}
      runName={runId.slice(-12)}
      runQuery={runQuery}
      resultQuery={resultQuery}
      graphQuery={graphQuery}
      mutateRuns={mutateRuns}
      mutateRunPayload={{
        runId: runQuery.data?.id,
      }}
      runUrl={`/demo/${correlationId}/analyses/${runId}`}
      onFilterChange={onFilterChange}
    />
  );
};

export default DemoAnalysisPage;
