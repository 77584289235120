import { APIResponse } from "./index";
import { baseURL, getToken } from "../api";

export interface Webhook {
  id: string;
  account_id: number;
  project_id: number;
  system: string;
  authorization: string;
  service_token?: string;
}

interface WebhookUpdate {
  is_active?: boolean;
  service_token?: string;
  authorization?: string;
}

interface WebhookCreate {
  id: string;
  system: string;
  authorization: string;
  service_token: string;
}

interface WebhookRequest {
  accountId: number;
  projectId: number;
  webhookId?: string;
}

interface WebhookUpdateRequest {
  accountId: number;
  projectId: number;
  webhookId: string;
  webhookUpdate: WebhookUpdate;
}

interface WebhookCreateRequest {
  accountId: number;
  projectId: number;
  webhook: WebhookCreate;
}

export function fetchWebhooksForProject({
  accountId,
  projectId,
}: WebhookRequest): Promise<APIResponse<Webhook[]>> {
  const url = baseURL + `/accounts/${accountId}/projects/${projectId}/webhooks`;
  let token = getToken();
  return fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token.access_token,
    },
  }).then((response) => response.json());
}

export function getWebhook({
  accountId,
  projectId,
  webhookId,
}: WebhookRequest): Promise<APIResponse<Webhook>> {
  const url =
    baseURL +
    `/accounts/${accountId}/projects/${projectId}/webhooks/${webhookId}/secure`;
  let token = getToken();
  return fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token.access_token,
    },
  }).then((response) => response.json());
}

export function updateWebhook({
  accountId,
  projectId,
  webhookId,
  webhookUpdate,
}: WebhookUpdateRequest): Promise<APIResponse<Webhook>> {
  const url =
    baseURL +
    `/accounts/${accountId}/projects/${projectId}/webhooks/${webhookId}`;
  let token = getToken();
  return fetch(url, {
    method: "PUT",
    headers: {
      Authorization: "Bearer " + token.access_token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(webhookUpdate),
  }).then((response) => response.json());
}

export function createWebhook({
  accountId,
  projectId,
  webhook,
}: WebhookCreateRequest): Promise<APIResponse<Webhook>> {
  const url = baseURL + `/accounts/${accountId}/projects/${projectId}/webhooks`;
  let token = getToken();
  return fetch(url, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token.access_token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(webhook),
  }).then((response) => response.json());
}
