import React, { useState } from "react";
import styled from "styled-components";
import {
  useParams,
  useNavigate,
  useSearchParams,
  Link,
} from "react-router-dom";
import { useQuery } from "react-query";

import { Breadcrumbs } from "../../components/header/breadcrumbs";
import { RunList } from "../../components/project/projectcard";
import { Button, LinkButton } from "../../components/button/Button";

import {
  Account,
  fetchAccountbyId,
  fetchCurrentUser,
  User,
} from "../../utilities/api";
import { Run, fetchRunsForProject, fetchRun } from "../../utilities/api/runs";

import { fetchProjectForAccount, Project } from "../../utilities/api/projects";

import { UploadDialog } from "../../components/project/upload";
import { RunSummary } from "../../components/project/projectSummary";
import { AnalysisId, InvocationId } from "../../components/results/resultsPage";
import { APIResponse } from "../../utilities/api/index";
import StyledPaginator from "../../components/results/pagination";

const DashboardPageContainer = styled.div`
  max-width: 70em;
`;

const ProjectPage: React.FC = () => {
  const [uploadDialogOpen, setUploadDialog] = useState(false);

  const navigate = useNavigate();
  const { accountId, projectId } = useParams();

  let [searchParams] = useSearchParams();
  let pageValue = searchParams.get("page") || "1";

  let page = parseInt(pageValue);

  const { data: user } = useQuery<User, Error>("user", fetchCurrentUser);

  if (!accountId && user) {
    navigate(`/accounts/${user?.accounts[0].id}`);
  }

  const { isSuccess: isAccountSuccess, data: account } = useQuery<
    Account,
    Error
  >(
    ["accounts", { accountId }],
    ({ queryKey }: any) =>
      fetchAccountbyId({ accountId: queryKey[1].accountId }),
    {
      enabled: !!accountId,
    }
  );

  const {
    isSuccess: isProjectSuccess,
    data: project,
    isError: isProjectError,
    error: projectError,
  } = useQuery<Project, Error>(
    ["projects", { accountId, projectId }],
    ({ queryKey }: any) =>
      fetchProjectForAccount({
        accountId: queryKey[1].accountId,
        projectId: queryKey[1].projectId,
      }),
    {
      // The query will not execute until the userId exists

      enabled: !!accountId && !!projectId,
    }
  );

  // If we're not authenticated, then navigate to "/login"
  if (
    isProjectError &&
    projectError &&
    !!projectError.name &&
    projectError.name === "MissingTokenError"
  ) {
    navigate("/login");
  }

  const { isSuccess: isRunSuccess, data: runsResponse } = useQuery<
    APIResponse<Run[]>,
    Error
  >(
    ["runs", accountId, projectId, page],
    ({ queryKey }: any) =>
      fetchRunsForProject({
        accountId: queryKey[1],
        projectId: queryKey[2],
        page: queryKey[3],
      }),
    {
      // The query will not execute until the userId exists

      enabled: isProjectSuccess,
    }
  );

  const runs = runsResponse?.data || [];

  let latestRunId = !!runsResponse && runs.length > 0 ? runs[0].id : 0;

  const { isSuccess: isLatestRunSuccess, data: latestRun } = useQuery<
    Run,
    Error
  >(
    ["runs", accountId, latestRunId],
    ({ queryKey }: any) =>
      fetchRun({
        accountId: queryKey[1],
        runId: queryKey[2],
      }),
    {
      // The query will not execute until the userId exists

      enabled: isRunSuccess && runs && runs.length > 0,
    }
  );

  return (
    <DashboardPageContainer>
      {account && isAccountSuccess ? (
        <Breadcrumbs
          links={[{ name: `${account.name}`, url: `/accounts/${accountId}` }]}
        />
      ) : (
        <Breadcrumbs links={[{ name: " ", url: `/accounts/${accountId}` }]} />
      )}
      {isProjectSuccess && project ? (
        <div>
          <h1 style={{ margin: "0.5em 0 0 0" }}>{project.name}</h1>
          <Link to={`/accounts/${accountId}/projects/${project.id}/edit`}>
            <LinkButton size="tiny" label="Edit" />
          </Link>
          <section>
            {isLatestRunSuccess && !!latestRun && isRunSuccess && !!runs ? (
              runs.length > 0 ? (
                <div>
                  <h2>
                    Latest Analysis{" "}
                    <AnalysisId
                      href={`/accounts/${latestRun.account_id}/projects/${latestRun.project_id}/analyses/${latestRun.id}`}
                    >
                      #{latestRun.id}
                    </AnalysisId>
                    {latestRun.metadata ? (
                      <InvocationId>
                        Invocation:{" "}
                        <Link
                          to={`/accounts/${latestRun.account_id}/projects/${latestRun.project_id}/analyses/${latestRun.id}`}
                        >
                          {latestRun.metadata.invocation_id}
                        </Link>
                      </InvocationId>
                    ) : undefined}
                  </h2>

                  <RunSummary run={latestRun} />
                </div>
              ) : (
                <div>
                  <h2>Latest Analysis</h2>
                  <p>
                    No analyses have been performed yet for this project.{" "}
                    <LinkButton
                      size="small"
                      label="Upload Artifacts"
                      onClick={() => {
                        setUploadDialog(true);
                      }}
                    />{" "}
                    to create your first analysis.
                  </p>
                </div>
              )
            ) : undefined}
          </section>
          <section>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <h2>Analyses</h2>
              <UploadDialog
                configuration={{
                  kind: "Run",
                  accountId: project.account_id,
                  projectId: project.id,
                }}
                open={uploadDialogOpen}
                setOpen={(open: boolean) => setUploadDialog(open)}
              />
              <Button
                size="tiny"
                label="Upload Artifacts"
                onClick={() => {
                  setUploadDialog(true);
                }}
              />
            </div>

            {isRunSuccess && runs ? (
              <div>
                <RunList key={project.id} runs={runs} />
                <StyledPaginator
                  page={page}
                  totalPages={
                    runsResponse && runsResponse.metadata.pagination
                      ? Math.ceil(
                          runsResponse.metadata.pagination.total_count / 10
                        )
                      : 1
                  }
                />
              </div>
            ) : (
              <p>No runs have been created for this project yet.</p>
            )}
          </section>
        </div>
      ) : (
        <p>Project does not exist.</p>
      )}
    </DashboardPageContainer>
  );
};
export default ProjectPage;
