import React, { useState } from "react";
import Graph from "graphology";

import { DisplayGraph } from "../../components/graph/graph";
import LoadingGraph from "../../components/loading/loadingGraph";

interface GraphContainerProps {
  graph: Graph;
  id: string;
}

export const GraphContainer: React.FC<GraphContainerProps> = ({
  id,
  graph,
}) => {
  const [overviewRendered, setOverviewRendered] = useState(false);

  if (graph) {
    return (
      <div>
        {overviewRendered === false ? (
          <LoadingGraph text="Loading ..." />
        ) : undefined}
        <div style={{ display: overviewRendered ? "block" : "hidden" }}>
          <DisplayGraph
            id={id}
            graph={graph}
            layout={true}
            afterRenderCallback={() => setOverviewRendered(true)}
          />
        </div>
      </div>
    );
  }
  return <LoadingGraph text="Loading ..." />;
};
