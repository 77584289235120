import React from "react";
import { useMutation, useQueryClient } from "react-query";

import { createProject } from "../../utilities/api/projects";
import { Button } from "../button/Button";
import { Input } from "../forms/forms";
import { ButtonNav, Dialog } from "../dialog/dialog";

interface ProjectCreateDialogProps {
  accountId: number;
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const ProjectCreateDialog: React.FC<ProjectCreateDialogProps> = ({
  accountId,
  open,
  setOpen,
}) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(createProject, {
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries("projects");
    },
  });

  const handleSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!e.target.projectName) {
      return;
    }

    mutation.mutate({
      accountId,
      name: e.target.projectName.value,
    });

    setOpen(false);
  };

  return (
    <Dialog
      id="projectCreateDialog"
      setOpen={setOpen}
      onSubmit={handleSubmit}
      open={open}
    >
      <h1>Create a Project</h1>
      <p>
        Create a new project to start tracking the development of your dbt
        Project.
      </p>
      <form onSubmit={handleSubmit}>
        <Input
          id="projectName"
          name="projectName"
          type="text"
          label="Project Name"
        />
        <ButtonNav>
          <Button label="Cancel" type="button" onClick={() => setOpen(false)} />
          <Button primary label="Submit" type="submit" />
        </ButtonNav>
      </form>
    </Dialog>
  );
};
