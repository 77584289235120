export const colors = {
  white: "#fefcfc",
  // consider #fdf2f2 in the future. Saturate the neutral.
  black: "#262a2d",
  primary: "#c42828",
  secondary: "#358b37",
  tertiary: "#e3b314",
  quaternary: "#dbd5d3",
  lightgray: "#b0b0b0",
  gray: "#898989",
  lightprimary: "#e88d8d",
};
