import { useState, AnimationEvent } from "react";
import styled from "styled-components";

import { colors } from "../../utilities/style";

const TypedTextContainer = styled.div`
  display: inline-block;

  vertical-align: top;
`;

interface TypedParagraphProps {
  duration: number;
  steps?: number;
}

const TypedParagraph = styled.p<TypedParagraphProps>`
  font-size: 1em !important;
  color: ${colors.primary} !important;

  margin: 0;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
  border-right: 2px solid ${colors.primary};
  width: 0;
  animation: typing ${(props) => props.duration / 2}s
      steps(${(props) => props.steps || 30}, end) alternate,
    blinking 0.9s infinite;
  animation-iteration-count: infinite;

  @keyframes typing {
    0% {
      width: 0%;
    }
    20% {
      width: 100%;
    }

    100% {
      width: 100%;
    }
  }

  @keyframes blinking {
    0% {
      border-right-color: transparent;
    }
    50% {
      border-right-color: ${colors.primary};
    }
    100% {
      border-right-color: transparent;
    }
  }
`;

interface TypedTextProps {
  wordList: string[];
  duration?: number;
}

export const TypedText: React.FC<TypedTextProps> = ({ wordList, duration }) => {
  const animationDuration = duration || 5;

  const [currentWordIndex, setCurrentWordIndex] = useState(0);

  const handleAnimation = (e: AnimationEvent<HTMLDivElement>) => {
    if (e.animationName !== "typing") {
      return;
    }

    if (e.elapsedTime % animationDuration !== 0) {
      return;
    }

    let newWordIndex = currentWordIndex + 1;

    if (newWordIndex === wordList.length) {
      newWordIndex = 0;
    }
    setCurrentWordIndex(newWordIndex);
  };

  return (
    <TypedTextContainer>
      <TypedParagraph
        duration={animationDuration}
        onAnimationIteration={handleAnimation}
        steps={wordList[currentWordIndex].length}
      >
        {wordList[currentWordIndex]}
      </TypedParagraph>
    </TypedTextContainer>
  );
};
