import React, { useState } from "react";
import { useQuery } from "react-query";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";

import { Button, LinkButton } from "../../../components/button/Button";
import { RunList } from "../../../components/project/projectcard";

import {
  fetchCurrentUser,
  User,
  getCorrelationId,
} from "../../../utilities/api";
import { Run, fetchDemoRuns, fetchDemoRun } from "../../../utilities/api/runs";

import { PageContainer } from "../../../components/page/PageContainer";
import { UploadDialog } from "../../../components/project/upload";
import styled from "styled-components";
import {
  AnalysisId,
  InvocationId,
} from "../../../components/results/resultsPage";
import { RunSummary } from "../../../components/project/projectSummary";
import { APIResponse } from "../../../utilities/api/index";
import StyledPaginator from "../../../components/results/pagination";

const DemoId = styled.a`
  font-size: 0.7em;
  vertical-align: middle;
`;

const DemoPage: React.FC = () => {
  const navigate = useNavigate();
  const { correlationId } = useParams();

  let [searchParams] = useSearchParams();
  let pageValue = searchParams.get("page") || "1";

  let page = parseInt(pageValue);

  const { data } = useQuery<User, Error>("user", fetchCurrentUser);

  if (data !== undefined) {
    navigate("/accounts");
  }

  const [uploadDialogOpen, setUploadDialog] = useState(false);
  const { isSuccess: isDemoRunSuccess, data: demoRunsResponse } = useQuery<
    APIResponse<Run[]>,
    Error
  >(["runs", correlationId, page], ({ queryKey }: any) =>
    fetchDemoRuns({
      correlationId: queryKey[1],
      page: queryKey[2],
    })
  );

  const demoRuns = demoRunsResponse ? demoRunsResponse.data : [];

  let latestRunId = "";
  if (!!demoRuns && demoRuns.length > 0) {
    latestRunId = demoRuns[0].id as string;
  }

  const { isSuccess: isLatestRunSuccess, data: latestRun } = useQuery<
    Run,
    Error
  >(
    ["runs", latestRunId],
    ({ queryKey }: any) =>
      fetchDemoRun({
        runId: queryKey[1],
      }),
    {
      // The query will not execute until the userId exists

      enabled: isDemoRunSuccess && demoRuns && demoRuns.length > 0,
    }
  );

  if (!correlationId) {
    let uuid = getCorrelationId();
    navigate(`/demo/${uuid}`, { replace: true });
    return <></>;
  } else {
    return (
      <PageContainer>
        <div>
          <h1>
            Demo{" "}
            <DemoId href={`/demo/${correlationId}`}>
              #{correlationId.slice(-12)}
            </DemoId>
          </h1>
          <section>
            {isDemoRunSuccess && !!demoRuns ? (
              isLatestRunSuccess && demoRuns.length > 0 && !!latestRun ? (
                <div>
                  <h2>
                    Latest Analysis{" "}
                    <AnalysisId
                      href={`/accounts/${latestRun.account_id}/projects/${latestRun.project_id}/analyses/${latestRun.id}`}
                    >
                      #{(latestRun.id as string).slice(-12)}
                    </AnalysisId>
                    {latestRun.metadata ? (
                      <InvocationId>
                        Invocation {latestRun.metadata.invocation_id}
                      </InvocationId>
                    ) : undefined}
                  </h2>
                  <RunSummary run={latestRun} />
                </div>
              ) : (
                <div>
                  <h2>Latest Analysis</h2>
                  <p>
                    No analyses have been performed yet for this project.{" "}
                    <LinkButton
                      size="small"
                      label="Upload Artifacts"
                      onClick={() => {
                        setUploadDialog(true);
                      }}
                    />{" "}
                    to create your first analysis.
                  </p>
                </div>
              )
            ) : undefined}
          </section>
          <section>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <h2>Analyses</h2>
              <UploadDialog
                configuration={{ kind: "DemoRun", correlationId }}
                open={uploadDialogOpen}
                setOpen={(open: boolean) => setUploadDialog(open)}
              />
              <Button
                size="tiny"
                label="Upload Artifacts"
                onClick={() => {
                  setUploadDialog(true);
                }}
              />
            </div>

            {isDemoRunSuccess && demoRuns ? (
              <div>
                <RunList isDemo key={correlationId} runs={demoRuns} />
                <StyledPaginator
                  page={page}
                  totalPages={
                    demoRunsResponse && demoRunsResponse.metadata.pagination
                      ? Math.ceil(
                          demoRunsResponse.metadata.pagination.total_count / 10
                        )
                      : 1
                  }
                />
              </div>
            ) : (
              <p>No runs have been created for this project yet.</p>
            )}
          </section>
        </div>
      </PageContainer>
    );
  }
};

export default DemoPage;
