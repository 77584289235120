interface FileUploadProps {
  setFile: any;
  label: string;
  name: string;
}

export const FileUpload = (props: FileUploadProps) => {
  const { setFile } = props;

  // useEffect(() => {
  //   if (fileText !== "") {
  //     let manifestFile = JSON.parse(fileText);
  //     store(manifestFile);
  //   }
  // }, [store, fileText]);

  const readFile = (event: any) => {
    var files = event.target.files;
    var file = files[0];

    var reader = new FileReader();
    reader.onload = (loadEvent) => {
      if (
        loadEvent.target &&
        loadEvent.target.result &&
        typeof loadEvent.target.result == "string"
      ) {
        setFile(JSON.parse(loadEvent.target.result));
      }
    };

    reader.readAsText(file);
  };

  return (
    <div className="fileUploader">
      <label className="fileInputLabel" htmlFor={props.name}>
        {props.label}:
      </label>

      <input
        id={props.name}
        type="file"
        accept="application/json"
        onChange={readFile}
      />
    </div>
  );
};
