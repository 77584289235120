import React, { useState } from "react";
import { Button } from "../button/Button";

interface SearchBoxProps {
  name: string;
  label: string;
  placeholder: string;
  onChange?: (filterChange: { [index: string]: string[] }) => {};
}

export const SearchBox: React.FC<SearchBoxProps> = ({
  name,
  label,
  placeholder,
  onChange,
}) => {
  const [filters, setFilters] = useState<string>("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setFilters(event.target.value);
  };

  return (
    <form
      style={{ display: "flex", alignItems: "center" }}
      onSubmit={(e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!!onChange) {
          onChange({ [name]: [filters] });
        }
      }}
    >
      <input
        id="entitySearch"
        type="search"
        placeholder={placeholder}
        style={{
          margin: "0 1em 0 0 ",
          padding: ".5em 14px",
          fontFamily: '"Roboto Mono",monospace',
        }}
        size={50}
        onChange={handleChange}
        value={filters}
      />

      <Button label="Filter" size="tiny" />
    </form>
  );
};
