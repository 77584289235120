import React from "react";
import styled from "styled-components";

import { Breadcrumbs, LinkDefinition } from "../header/breadcrumbs";
import { GraphContainer } from "../graph/graphContainer";

import { Run } from "../../utilities/api/runs";
import { ResultGroup } from "../../utilities/api/results";
import RunMetadataCard from "./runMetadata";
import Graph from "graphology";
import ResultList from "./resultList";
import { useSearchParams } from "react-router-dom";
import { UseQueryResult } from "react-query";
import { Project } from "../../utilities/api/projects";
import LoadingGraph from "../loading/loadingGraph";
import { RunDetails } from "./runDetails";
import { APIResponse } from "../../utilities/api/index";
import { MultiCheckbox } from "../forms/select";
import { SearchBox } from "../forms/searchBox";

const AnalysisPageContainer = styled.div`
  max-width: 70em;
`;

const AnalysisHeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const AnalysisId = styled.a`
  // font-size: 0.7em;
  // vertical-align: middle;
`;

export const InvocationId = styled.p`
  font-size: 0.5em;
  font-weight: lighter;
  margin: 0.5em 0;
`;

interface ResultsPageProps {
  breadcrumbs: LinkDefinition[];
  projectQuery?: UseQueryResult<Project, Error>;
  runId: string;
  runName?: string;
  runQuery: UseQueryResult<Run, Error>;
  resultQuery: UseQueryResult<APIResponse<ResultGroup[]>, Error>;
  graphQuery: UseQueryResult<Graph, Error>;
  mutateRuns: any;
  mutateRunPayload: any;
  runUrl?: string;
  onFilterChange?: any;
}

const ResultsPage: React.FC<ResultsPageProps> = ({
  breadcrumbs,
  projectQuery,
  runId,
  runName,
  runQuery,
  resultQuery,
  mutateRuns,
  graphQuery,
  mutateRunPayload,
  runUrl,
  onFilterChange,
}) => {
  let [searchParams] = useSearchParams();
  let pageValue = searchParams.get("page") || "1";

  let page = parseInt(pageValue);

  return (
    <AnalysisPageContainer>
      <Breadcrumbs links={breadcrumbs} />
      <h1>
        {!!projectQuery ? projectQuery.data?.name : "Analysis"}{" "}
        <AnalysisId href={runUrl}>#{!!runName ? runName : runId}</AnalysisId>
        {runQuery.data?.metadata ? (
          <InvocationId>
            Invocation: {runQuery.data?.metadata.invocation_id}
          </InvocationId>
        ) : undefined}
      </h1>
      <AnalysisHeaderSection>
        {runQuery.isSuccess && !runQuery.isError && runQuery.data ? (
          <RunMetadataCard
            run={runQuery.data}
            retryHandler={() => {
              mutateRuns.mutate(mutateRunPayload);
            }}
          />
        ) : undefined}
        <div className="mobileHidden" style={{ margin: "0 0 0 auto" }}>
          {runQuery.data && resultQuery.isSuccess && resultQuery.data ? (
            <div>
              {runQuery.data &&
              resultQuery.isSuccess &&
              resultQuery.data &&
              runQuery.data.graph_url &&
              graphQuery.isSuccess &&
              graphQuery.data ? (
                <GraphContainer id="overview" graph={graphQuery.data} />
              ) : undefined}
            </div>
          ) : undefined}
        </div>
      </AnalysisHeaderSection>

      {runQuery.isSuccess &&
      !runQuery.isError &&
      runQuery.data &&
      runQuery.data.status === "success" ? (
        <div>
          <section>
            <h2>Summary</h2>
            <RunDetails metrics={runQuery.data.statistics || {}} />
          </section>
          <section>
            <h2>Results</h2>
            <SearchBox
              name="select"
              label="Search"
              placeholder="1+model+2"
              onChange={onFilterChange}
            />
            <details>
              <summary>Filters</summary>
              <div style={{ display: "flex", gap: "2em", margin: "1em" }}>
                <MultiCheckbox
                  name="severity"
                  label="Severity"
                  options={[
                    { value: "low", label: "Low" },
                    { value: "medium", label: "Medium" },
                    { value: "high", label: "High" },
                  ]}
                  multiple
                  onChange={onFilterChange}
                />
                <MultiCheckbox
                  name="ruleSet"
                  label="Rule Set"
                  options={[
                    { value: "Tests and Docs", label: "Tests and Docs" },
                    { value: "Execution", label: "Execution" },
                    { value: "Structure", label: "Structure" },
                  ]}
                  multiple
                  onChange={onFilterChange}
                />
              </div>
            </details>

            {resultQuery.isSuccess && resultQuery.data ? (
              <ResultList
                resultGroups={resultQuery.data.data}
                page={page}
                totalPages={
                  resultQuery.data.metadata.pagination
                    ? Math.ceil(
                        resultQuery.data.metadata.pagination.total_count / 20
                      )
                    : 1
                }
              />
            ) : resultQuery.error && resultQuery.data ? (
              <div>{resultQuery.error.message}</div>
            ) : (
              <LoadingGraph text={"Loading"}></LoadingGraph>
            )}
          </section>
        </div>
      ) : runQuery.isLoading ? (
        <div>Results pending.</div>
      ) : undefined}
    </AnalysisPageContainer>
  );
};

export default ResultsPage;
